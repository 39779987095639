import React, { useState } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import { useNavigate } from "react-router-dom";

import PieChartIcon from '@mui/icons-material/PieChart';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
 
import ArrowRightIcon from '@mui/icons-material/ArrowRight';


const Navbar = ({ profile }) => {
  const navigate = useNavigate();
  const currentTab = window.location.href.split("/")[3];

 
  const [list, setList]=useState(null)
  const handleClick = (type) => {
     
    setList(type)
  }
   const [navList, setNavList]=useState(null)
  const handleNavClick = (type) => {
    
    setNavList(type)
}

  const navItemss = [
  { icon: DashboardIcon, label: "Dashboard", links: ["Link 1", "Link 2", "Link 3"] },
  { icon: ViewInArIcon, label: "Production", links: ["Warehouse", "Product", "Inventory", "Supplier"] },
  { icon: ManageHistoryIcon, label: "HRMS", links: ["Link 1", "Link 2", "Link 3"] },
  { icon: PieChartIcon, label: "Report", links: ["Link 1", "Link 2", "Link 3"] },
  { icon: ManageAccountsIcon, label: "CRM", links: ["Link 1", "Link 2", "Link 3"] },
  { icon: SettingsIcon, label: "Settings", links: ["Link 1", "Link 2", "Link 3"] },
  ];
  const NavItem = ({ Icon, label, links }) => (
  <div className="flex flex-col items-center gap-1 cursor-pointer p-3 text-[#7F7F7F]  hover:text-primColor hover:border-b-2 hover:border-primColor">
    <Icon sx={{ fontSize: "20px" }} />
    <div className="relative group hover:text-primColor" onClick={(e)=>setNavList(label)}>
        <p className="font-medium text-[14px] hover:text-primColor text-[#7F7F7F]">{label}</p>
        {navList === "Production" &&
          <div className="absolute  group-hover:block bg-white text-black mt-4 min-w-[160px] shadow-lg z-10">
            {links.map((link, index) =>
            (
              <div key={index} className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]" onClick={() => handleClick(link)}>
                <a href="#" className="block px-4 py-2 text-[14px]" >
                  {link} PRodu
                </a>
                <div className="ml-auto mr-5">
                  <ArrowRightIcon />
                </div>
              </div>
            ))}
          </div>}
        {list === "Warehouse" &&
          <div className="absolute  group-hover:block bg-white text-black mt-4 min-w-[180px] shadow-lg z-10 ml-40">
 
            <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]">
              <a href="#" className="block px-4 py-2 text-[14px] whitespace-nowrap">
                
              </a>
              <div className="ml-auto mr-5">
                <ArrowRightIcon />
              </div>
            </div>
 
          </div>}

    </div>
  </div>
);


  return (
   <div className="flex justify-between items-center bg-white border px-[125px]">
      <div className="flex flex-col items-center gap-1 cursor-pointer p-3 text-[#7F7F7F]  hover:text-primColor hover:border-b-2 hover:border-primColor">
    <DashboardIcon sx={{ fontSize: "20px" }} />
    <div className="relative group hover:text-primColor" onClick={(e)=>setNavList("Dashboard")}>
          <p className="font-medium text-[14px] hover:text-primColor text-[#7F7F7F]">Dashboard</p>
         
         

    </div>
      </div>
      
       
      <div className="flex flex-col items-center gap-1 cursor-pointer p-3 text-[#7F7F7F] hover:text-primColor hover:border-b-2 hover:border-primColor">
  <ViewInArIcon sx={{ fontSize: "20px" }} />
  <div className="relative group hover:text-primColor" onClick={(e) => setNavList("Production")}>
    <p className="font-medium text-[14px] hover:text-primColor text-[#7F7F7F]">Production</p>
    {navList === "Production" && (
      <div
        className="absolute left-1/2 transform -translate-x-1/2 group-hover:block bg-white text-black mt-4 min-w-[180px] shadow-lg z-10"
      >
        <div
          className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]"
          onClick={() => handleClick("Warehouse")}
        >
          <a href="#" className="block px-4 py-2 text-[14px]">
            Warehouse
          </a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
        </div>
        <div
          className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]"
                onClick={() => { handleClick("Product"); navigate("product")}}
        >
          <a href="#" className="block px-4 py-2 text-[14px]">
            Product
          </a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
        </div>
        <div
          className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]"
          onClick={() => handleClick("Inventory")}
        >
          <a href="#" className="block px-4 py-2 text-[14px]">
            Inventory
          </a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
              </div>
                <div
          className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]"
          onClick={() => handleClick("processloss")}
        >
          <a href="/processloss" className="block px-4 py-2 text-[14px]">
            Process Loss
          </a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
              </div>
                 <div
          className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]"
          onClick={() => handleClick("processloss")}
        >
          <a href="/performanceinvoice" className="block px-4 py-2 text-[14px]">
            Performance Invoice
          </a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
        </div>
        <div
          className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]"
          onClick={() => handleClick("Supplier")}
        >
          <a href="#" className="block px-4 py-2 text-[14px]">
            Suppliers
          </a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
              </div>
                <div
          className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]"
          onClick={() => handleClick("productionorder")}
        >
          <a href="/productionorder" className="block px-4 py-2 text-[14px]">
            Production Order
          </a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
        </div>
      </div>
    )}
    {list === "Warehouse" && (
      <div
        className="absolute left-1/2 transform -translate-x-1/2 group-hover:block bg-white text-black mt-4 min-w-[160px] shadow-lg z-10 ml-[172px]"
      >
        <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]">
          <a href="#" className="block px-4 py-2 text-[14px]">
            New Warehouse
                </a>
          
          
              </div>
              <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]">
          <a href="#" className="block px-4 py-2 text-[14px]">
          Manage Groups
                </a>        
          
              </div>
              
      </div>
          )}
            {list === "Product" && (
      <div
        className="absolute left-1/2 transform -translate-x-1/2 group-hover:block bg-white text-black mt-16 min-w-[160px] shadow-lg z-10 ml-[172px]"
      >
        <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]" onClick={()=>navigate('product/productform')}>
          <a href="#" className="block px-4 py-2 text-[14px]">
            New Product
                </a>
          
          
              </div>
              <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]"onClick={()=>navigate('product/productgroup')}>
          <a href="#" className="block px-4 py-2 text-[14px]">
          Manage Groups
                </a>
                
          
          
              </div>
                 
              
      </div>
          )}
                {list === "Inventory" && (
      <div
        className="absolute left-1/2 transform -translate-x-1/2 group-hover:block bg-white text-black mt-[90px] min-w-[160px] shadow-lg z-10 ml-[172px]"
      >
        <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]">
          <a href="#" className="block px-4 py-2 text-[14px]">
            Stock
                </a>
          
          
              </div>
              <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]">
          <a href="#" className="block px-4 py-2 text-[14px]">
          Price
                </a>
                
          
          
              </div>
                 <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]">
          <a href="#" className="block px-4 py-2 text-[14px]">
          New Inventory
                </a>
                
          
          
              </div>
              
      </div>
          )}
           {list === "Supplier" && (
      <div
        className="absolute left-1/2 transform -translate-x-1/2 group-hover:block bg-white text-black mt-32 min-w-[160px] shadow-lg z-10 ml-[172px]"
      >
        <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]">
          <a href="#" className="block px-4 py-2 text-[14px]">
            New Supplier
                </a>
          
          
              </div>
              <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]">
          <a href="#" className="block px-4 py-2 text-[14px]">
          Purchase Order
                </a>
                
          
          
              </div>
              
              
      </div>
          )}
  </div>
</div>

      
        <div className="flex flex-col items-center gap-1 cursor-pointer p-3 text-[#7F7F7F] hover:text-primColor hover:border-b-2 hover:border-primColor">
  <ManageHistoryIcon sx={{ fontSize: "20px" }} />
  <div className="relative group hover:text-primColor" onClick={(e) => setNavList("HRMS")}>
    <p className="font-medium text-[14px] hover:text-primColor text-[#7F7F7F]">HRMS</p>
    {navList === "HRMS" && (
      <div className="absolute left-1/2 transform -translate-x-1/2 bg-white text-black mt-4 min-w-[160px] shadow-lg z-10">
              <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]" onClick={() => { handleClick("employee");navigate("employee") }}>
          <a href="#" className="block px-4 py-2 text-[14px]">Employees</a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
        </div>
        <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]" onClick={() => handleClick("workorder")}>
          <a href="#" className="block px-4 py-2 text-[14px]">Work Order</a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
        </div>
              <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]" onClick={() => { handleClick("timesheet"); navigate("/timesheet") }}>
          <a href="#" className="block px-4 py-2 text-[14px]">Timesheet</a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
              </div>
               <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]" onClick={() => { handleClick(" "); navigate("/materialrequest") }}>
          <a href="#" className="block px-4 py-2 text-[14px]">Material Request</a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
              </div>
               <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]" onClick={() => { handleClick(" "); navigate("/materialrequest") }}>
          <a href="/productionschedulepage" className="block px-4 py-2 text-[14px]">Production Schedule</a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
              </div>
               <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]" onClick={() => { handleClick(" "); navigate("/salesorder") }}>
          <a href="/salesorder" className="block px-4 py-2 text-[14px]">Sales Order</a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
              </div>
               
      </div>
    )}
    {list === "employee" && (
      <div className="absolute left-1/2 transform -translate-x-1/2 group-hover:block bg-white text-black mt-4 min-w-[160px] shadow-lg z-10 ml-40">
        <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]"onClick={()=>navigate('employee/teams')}>
          <a href="#" className="block px-4 py-2 text-[14px]">Manage Team</a>
          
              </div>
               <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]" onClick={()=>navigate('/employee/shiftmanagement')}>
          <a href="#" className="block px-4 py-2 text-[14px]">Manage Shift</a>
         
              </div>
                             <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]">
          <a href="#" className="block px-4 py-2 text-[14px]">New Shift</a>
         
        </div>

      </div>
          )}
            {list === "workorder" && (
      <div className="absolute left-1/2 transform -translate-x-1/2 group-hover:block bg-white text-black mt-10 min-w-[160px] shadow-lg z-10 ml-40">
        <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]">
          <a href="#" className="block px-4 py-2 text-[14px]">New WorkOrder</a>
          
              </div>
               <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]">
          <a href="#" className="block px-4 py-2 text-[14px]">Recurring Job</a>
         
              </div>
                              

      </div>
          )}
              {list === "timesheet" && (
      <div className="absolute left-1/2 transform -translate-x-1/2 group-hover:block bg-white text-black mt-16 min-w-[160px] shadow-lg z-10 ml-40">
        <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]" onClick={()=>navigate("/timesheet/timeoffpage")}>
          <a href="#" className="block px-4 py-2 text-[14px]">Manage Timeoff</a>
          
              </div>
               <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]" onClick={()=>navigate('/timesheet/timesheetapproval')}>
          <a href="#" className="block px-4 py-2 text-[14px] whitespace-nowrap">TimeSheet Approval</a>
         
              </div>
               
                              

      </div>
    )}
  </div>
</div>

     <div className="flex flex-col items-center gap-1 cursor-pointer p-3 text-[#7F7F7F]  hover:text-primColor hover:border-b-2 hover:border-primColor">
  <PieChartIcon sx={{ fontSize: "20px" }} />
  <div className="relative group hover:text-primColor" onClick={(e) => {
    setNavList("Reports");
    navigate("/report");
  }}>
          <p className={`font-medium text-[14px] hover:text-primColor text-[#7F7F7F]`}>Reports</p>
          
    {navList === "Reports" &&
      <div className="absolute left-1/2 transform -translate-x-1/2 bg-white text-black mt-4 min-w-[160px] shadow-lg z-10">
        <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]" onClick={() => handleClick("newfolder")}>
          <a href="#" className="block px-4 py-2 text-[14px]">
            New Folders
          </a>
        </div>
              <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]" onClick={() => { handleClick("schedulereport"); navigate('report/scheduledreport') }}>
          <a  className="block px-4 py-2 text-[14px]">
            Schedule Reports
          </a>
        </div>
      </div>
    }
  </div>
</div>

      <div className="flex flex-col items-center gap-1 cursor-pointer p-3 text-[#7F7F7F]  hover:text-primColor hover:border-b-2 hover:border-primColor">
  <ManageAccountsIcon sx={{ fontSize: "20px" }} />
  <div className="relative group hover:text-primColor" onClick={(e) => setNavList("CRM")}>
    <p className="font-medium text-[14px] hover:text-primColor text-[#7F7F7F]">CRM</p>
    {navList === "CRM" && (
      <div className="absolute left-1/2 transform -translate-x-1/2 bg-white text-black mt-4 min-w-[160px] shadow-lg z-10">
              <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]" onClick={() => { handleClick("invoice"); navigate("/invoice")}}>
          <a href="#" className="block px-4 py-2 text-[14px]">Invoice</a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
        </div>
              <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]" onClick={() => { handleClick("quotation"); navigate(`/quotation`) }}>
          <a href="#" className="block px-4 py-2 text-[14px]">Quotation</a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
        </div>
              <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]" onClick={() => { handleClick("customer"); navigate(`/customer`)}}>
          <a href="#" className="block px-4 py-2 text-[14px]">Customer</a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
        </div>
              <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]" onClick={() => { { handleClick("Job") }; }}>
          <a href="/job" className="block px-4 py-2 text-[14px]">Job</a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
        </div>
        <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]" onClick={() => handleClick("Proposal")}>
          <a href="/proposal" className="block px-4 py-2 text-[14px]">Proposal</a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
        </div>
              <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]" onClick={() => {
                handleClick("order"); 
    
                
              }}>
          <a href="/purchase" className="block px-4 py-2 text-[14px]">Orders</a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
        </div>
        <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]"  onClick={() => {
    handleClick("Leads");
    navigate("/lead");
  }}>
          <a href="#" className="block px-4 py-2 text-[14px]">Leads</a>
          <div className="ml-auto mr-5">
            <ArrowRightIcon />
          </div>
              </div>
              
      </div>
    )}
    {list === "invoice" && (
      <div className="absolute left-1/2 transform -translate-x-1/2 group-hover:block bg-white text-black mt-4 min-w-[160px] shadow-lg z-10 ml-40"onClick={()=>navigate("/invoice/invoiceform")}>
        <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]">
          <a href="#" className="block px-4 py-2 text-[14px]">New Invoice</a>
 
        </div>
      </div>
          )}
          {list === "quotation" && (
      <div className="absolute left-1/2 transform -translate-x-1/2 group-hover:block bg-white text-black mt-12 min-w-[160px] shadow-lg z-10 ml-40" onClick={()=>navigate("/quotation/quotationform")}>
        <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]">
          <a href="#" className="block px-4 py-2 text-[14px]">New Quotation</a>
        </div>
      </div>
          )}
          {list === "customer" && (
      <div className="absolute left-1/2 transform -translate-x-1/2 group-hover:block bg-white text-black mt-[94px] min-w-[160px] shadow-lg z-10 ml-40"onClick={()=>navigate("/customer/customerform")}>
        <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]">
          <a href="#" className="block px-4 py-2 text-[14px]">New Customer</a>
 
        </div>
      </div>
          )}
          {/* {list === "Job" && (
      <div className="absolute left-1/2 transform -translate-x-1/2 group-hover:block bg-white text-black mt-[124px] min-w-[160px] shadow-lg z-10 ml-40">
        <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]">
          <a href="jobform" className="block px-4 py-2 text-[14px]">New Job</a>
 
        </div>
      </div>
          )} */}
 
          {/* {list === "Proposal" && (
      <div className="absolute left-1/2 transform -translate-x-1/2 group-hover:block bg-white text-black mt-[169px] min-w-[160px] shadow-lg z-10 ml-40">
        <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]">
          <a href="#" className="block px-4 py-2 text-[14px]">New Proposal</a>
 
        </div>
      </div>
          )} */}
          {/* {list === "order" && (
      <div className="absolute left-1/2 transform -translate-x-1/2 group-hover:block bg-white text-black  mt-[196px] min-w-[160px] shadow-lg z-10 ml-40">
        <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]">
          <a href="#" className="block px-4 py-2 text-[14px]">New Order</a>
 
        </div>
      </div>
          )} */}
          {list === "Leads" && (
      <div className="absolute left-1/2 transform -translate-x-1/2 group-hover:block bg-white text-black mt-[239px] min-w-[160px] shadow-lg z-10 ml-40">
        <div className="flex items-center hover:bg-primColor hover:text-white text-[#7F7F7F]">
          <a href="#" className="block px-4 py-2 text-[14px]">New Lead</a>
 
              </div>
      </div>
    )}
  </div>
</div>

       <div className="flex flex-col items-center gap-1 cursor-pointer p-3 text-[#7F7F7F]  hover:text-primColor hover:border-b-2 hover:border-primColor border-transparent" onClick={()=>navigate(`/settings`)}>
    <SettingsIcon sx={{ fontSize: "20px" }} />
    <div className="relative group hover:text-primColor" onClick={(e)=>setNavList("Settings")}>
          <p className="font-medium text-[14px] hover:text-primColor text-[#7F7F7F]">Settings</p>
         

    </div>
      </div>
  </div>

  );
};

export default Navbar;



