import AddIcon from "@mui/icons-material/Add";
import React, { useCallback, useState } from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SubHeader from "../../Components/Molecules/Subheader";
import FilterSortby from "../../Components/Molecules/FilterSortBy";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
 
import FilterWrapper from "../../Components/Molecules/FilterWrapper"; 
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TableButton from "../../Components/Atoms/TableButton";
import { TableIconConstant } from "../../constants";
const BomPage = () => {
    const navigate=useNavigate()
     const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const onClickFilter = () => {
    handleOpen();
  };
   
  const [page, setPage] = useState(1);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");

  const sortList = [
    { name: "Created Date", value: "createdAt-ASC" },
    { name: "Created Date", value: "createdAt-DSC" },
    { name: "Name-ASC", value: "name-ASC" },
    { name: "Name-DSC", value: "name-DSC" },
  ];
    
  

  return (
      <>
          
    
      <div className="">
     <SubHeader
          headings={[
            {
              name: "Design",
                      },
              {
              name: "View BOM ",
                      },
               
               
                  ]}
                    actions={[
            {
              name: "View BOM",
              onclick: () => {
                navigate("/bom/form");
              },
              icon: <AddIcon sx={{ fontSize: "16px" }} />,
              type: "button",
                        },
                        {
                type: "menu_drop_down",
              
                children: [
                  {
                    label:  'Add Material',
                    
                     
                  },
                  {
                    label:   'Add Supplier',
                    
                     
                    }, {
                      label:"Convert to Production Order",
                    }, {
                      label:"Clone BOM"
                    }, 
                  {
                      label:"Share BOM"
                    },  {
                      label:"Delete BOM"
                    },  
                ],
                name:  "More",
                icon: (
                  <KeyboardArrowDownIcon
                     
                    sx={{ fontSize: "16px", marginLeft: "8px" }}
                  />
                ),
             
              },
          ]}
          
              />
          

        <>
          <FilterSortby
            exportBtn={false}
            totalPage={"3"}
            getPageNumber={setPage}
            getQuickFilter={setQuickFilter}
            getSortByValue={setSortBy}
            sortList={sortList}
            roleList={[{ name: "All Roles", _id: "All Roles", disabled: true }]}
            showFilter={true}
            onClickFilter={onClickFilter}
                      
          />
          <div className="overflow-auto">
            <table className="bg-[#ffffff] border border-gray-300 border-collapse w-full">
              <tbody>
                              <tr className="border-b border-gray-300">
                                   <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                #
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                material id
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"Quantity"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"units"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"supplier"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"Assembly"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"Price"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"Payment"}
                  </th>

                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"delivery"}
                                  </th>
                               
                                   <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"Actions"}
                  </th>
                </tr>

                <tr className="border-b border-gray-300">
                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-primColor py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    PU-5901
                                  </td>
                                      <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                Productions
                                  </td>
                                   <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                NRe cus
                  </td>
               
                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    Business development
                                  </td>
                                   <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    123                  </td> <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
32
                  </td>
  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    Business development
                  </td>  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#00933B] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    <button className="bg-[#CFFFE2] py-2 px-3 rounded-2xl">
                      Approved
                    </button>
                                  </td>  
                 
                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#CF7B04] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    <button className="bg-[#FFE9D6] py-2 px-3 rounded-2xl">
                      View
                    </button>
                                  </td>

               
                                  
              
                                      <td className="flex gap-2 text-sm font-medium leading-4 tracking-normal text-left text-[#CF7B04] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                     <TableButton
                        size={"small"}
                        buttonType={TableIconConstant.edit}
                        
                                      />
                                       <TableButton
                        size={"small"}
                        buttonType={TableIconConstant.delete}
                        
                      />  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      </div>{" "}
    </>
  );
};

export default BomPage;
