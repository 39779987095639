import React from "react";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";

export default function SalesOrderActivites() {
  return (
    <>
      <div className="">
        <div>
          <ComponentTitle title="Lead ACTIVITY" mt="5" mb="2" />
        </div>

        <div className="flex gap-4 pl-5 pt-2 overflow-auto h-[60vh]">
          <div className="edit-job-activity-timeline-con2">
            <div className="relative border-l border-gray-300 pl-8 pt-3 pb-2.5 break-words">
              <div className="w-4 h-4 rounded-full bg-white border-4 border-primColor absolute -left-2 top-4"></div>
              <h1 className="m-0 text-[#140C00] text-[13px] font-medium">
                {"05/05/2024"}
              </h1>
              <div className="edit-job-name;activuty-container">
                <p className="text-[14px] font-medium">
                  <span className="text-primColor text-[14px] font-medium">
                    {"Pavi"}
                  </span>{" "}
                  {"updated status to New for Lead Sample"}
                </p>
              </div>
            </div>
            <div className="relative border-l border-gray-300 pl-8 pt-3 pb-2.5 break-words">
              <div className="w-4 h-4 rounded-full bg-white border-4 border-primColor absolute -left-2 top-4"></div>
              <h1 className="m-0 text-[#140C00] text-[13px] font-medium">
                {"05/05/2024"}
              </h1>
              <div className="edit-job-name;activuty-container">
                <p className="text-[14px] font-medium">
                  <span className="text-primColor text-[14px] font-medium">
                    {"Pavi"}
                  </span>{" "}
                  {"updated status to New for Lead Sample"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
