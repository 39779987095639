import React, { useRef, useState } from "react";
import Whitespace from "../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../Components/Atoms/ComponentTitle";
import CustomInput from "../../Components/Atoms/CustomInput";
import TextArea from "../../Components/Atoms/TextArea";
import { TableIconConstant } from "../../constants";
import CustomButton from "../../Components/Atoms/CustomButton";
import FileInput from "../../Components/Atoms/FileInput";
import CustomSelect from "../../Components/Atoms/CustomSelect";
import InputLable from "../../Components/Atoms/InputLable";
import SubHeader from "../../Components/Molecules/Subheader";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
 
 

const ProjectsProductionForm = () => {
    const navigate=useNavigate()
    const handleFilesChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const obj = {
        file_name: e.target.files[i].name,
        url: "",
        // created_by: props.userId,
        attachment: e.target.files[i],
      };
    }
  };
  const hiddenFileInput = useRef(null);
  const handleUploadBtn = () => {
    hiddenFileInput?.current?.click();
  };

  
  return (
      <>
          <SubHeader
          headings={[
            {
              name: `Production Order`,
              url: "/productionschedulepage",
            },
            {
                name: `Project no. 5901`,
              url: "/productionschedulepage",
                
                  },
            {
              name: ` New Production`,
            },
          ]}
          actions={[
           

            {
               type: "button",
              btnType: "submit",
                  name: "Save",
               onclick:()=>navigate("/allproductionOrderpage")

              
            },
          ]}
        />
          
      <Whitespace mt="23px" className={"px-7 py-7 w-full"}>
              <ComponentTitle title={"production schedule settings"} />
              <div class="grid grid-cols-[1fr_3fr_2fr] gap-4">
                  <div>
                       <CustomInput
            margin="true"
            labelText="Prefix"
            name="username"
            placeholder="AB-001"
            type="text"
          />
  </div>
                  <div>
                       <CustomInput
            margin="true"
            labelText="Title"
            name="username"
            placeholder="Enter Title"
            type="text"
          />
                  </div>
                  <div>
                       <CustomSelect
            margin="true"
            labelText="Assign Team"
            name="username"
            placeholder="Choose a Team"
            type="text"
          />
                  </div>
</div>
        
        <div className="columnThree">
          <CustomInput
            margin="true"
            labelText="Tags"
            name="username"
            placeholder="Choose Recurrence"
            type="text"
                  />
                  <div>
                      <div className="mb-2">
                          <InputLable labelText="Start Time" />
                          </div>
                      <div className="flex gap-2 w-full">
                          <div className="w-[50%]">
          <CustomInput       
    
            type="date"
                              />
                          </div>
                           <div className="w-[50%]">
          <CustomInput
             
             type="time"
                              />
                              </div>
                      </div>
                  </div>
                    <div>
                      <div className="mb-2">
                          <InputLable labelText="End Time" />
                          </div>
                      <div className="flex gap-2 w-full">
                          <div className="w-[50%]">
          <CustomInput       
    
            type="date"
                              />
                          </div>
                           <div className="w-[50%]">
          <CustomInput
             
             type="time"
                              />
                              </div>
                      </div>
                      </div>
              </div>
              
        <TextArea labelText={"Description"} placeholder={"Enter Description"} />
          </Whitespace>
          
        
      <Whitespace className={"mt-5"}>
        <div className="flex items-center justify-between mb-10">
          <ComponentTitle title={"PARTS & SERVICES"}></ComponentTitle>
          <div className="flex">
            <CustomButton size="medium" buttonType={TableIconConstant.add}>
              {"Upload"}
            </CustomButton>
          </div>
        </div>
        <input
          onChange={handleFilesChange}
          ref={hiddenFileInput}
          style={{ display: "none" }}
          type="file"
          multiple
        />
        {/* {formik.values.attachments.length === 0 ? ( */}
        <div className="flex flex-col justify-center items-center py-9">
          <h1 className="text-[17px] font-semibold leading-[19px] text-black mb-[16px]">
            {"Add Parts & Services"}
          </h1>
          <p className="text-[14px] font-normal leading-[15px] text-[#9d9d9d] mb-[25px]">
            {
              "Add any parts or services to this production schedule"
            }
          </p>

          <CustomButton
            size="medium"
            onClick={handleUploadBtn}
            buttonType={TableIconConstant.add}
          >
            {"Upload"}
          </CustomButton>
        </div>
          </Whitespace>
          
          <Whitespace className={"mt-5"}>
              <div className="flex items-center justify-between mb-10">
                <ComponentTitle
                  title={"work instruction attachments"}
                ></ComponentTitle>
                <div className="flex">
                  <CustomButton
                    size="medium"
                    buttonType={TableIconConstant.add}
                  >
                    {"Upload"}
                  </CustomButton>
                </div>
              </div>
              <input
                onChange={handleFilesChange}
                ref={hiddenFileInput}
                style={{ display: "none" }}
                type="file"
                multiple
              />
              {/* {formik.values.attachments.length === 0 ? ( */}
              <div className="flex flex-col justify-center items-center py-9">
                <h1 className="text-[17px] font-semibold leading-[19px] text-black mb-[16px]">
                  {"Upload Attachments"}
                </h1>
                <p className="text-[14px] font-normal leading-[15px] text-[#9d9d9d] mb-[25px]">
                  {
                    "Add any parts or services of any custom line item to this Proposal"
                  }
                </p>

                <CustomButton
                  size="medium"
                  onClick={handleUploadBtn}
                  buttonType={TableIconConstant.add}
                >
                  {"Upload"}
                </CustomButton>
              </div>
              {/* ) : (
              <PreviewFiles
                data={formik.values.attachments}
                removeFile={removeFile}
                show="div"
              /> */}
              {/* )} */}
            </Whitespace>
    </>
  );
};

export default ProjectsProductionForm;
