import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../Views/Login/Login";
import Forgotpassword from "../Views/Login/ForgotPassword";
import ResetPassword from "../Views/Login/ResetPassword";
import Layout from "../Components/Organisms/Layout";
import EmployeePage from "../Views/Employee";
import EmployeeForm from "../Views/Employee/EmployeeForm";
import Team from "../Views/Employee/Teams";
import TeamForm from "../Views/Employee/Teams/TeamForm";
import ShiftManagementPage from "../Views/Employee/ShiftManagement";
import EmployeeViewPage from "../Views/Employee/EmployeeView";
import QuotationPage from "../Views/Quotation";
import QuotationForm from "../Views/Quotation/quotationForm";
import LeadPage from "../Views/Leads";
import LeadForm from "../Views/Leads/NewLead";
import SettingPage from "../Views/Settings";
import LeadSettings from "../Views/Settings/LeadSettings";
import LeadViewPage from "../Views/Leads/LeadView";
import InvoicePage from "../Views/Invoice";
import InvoiceForm from "../Views/Invoice/invoiceForm";
import PurchasePage from "../Views/PurchaseOrder";
import QuoteAndInvoiceSettings from "../Views/Settings/QuoteAndInvoice";
import InvoiceView from "../Views/Invoice/invoiceView";
import AccountSetting from "../Views/Settings/AccountSettings";
import EmployeeFieldsSettings from "../Views/Settings/CustomFeildSettings/EmployeeFeildsSettings";
import HolidaySettingsPage from "../Views/Settings/OtherSettings/HolidaySettings";
import CustomRolesPage from "../Views/Settings/CustomRoles";
import EditCustomRole from "../Views/Settings/CustomRoles/editCustomRoles";
import CustomerPage from "../Views/Customer";
import CustomerForm from "../Views/Customer/customerForm";
import ProductPage from "../Views/Product";
import ProductForm from "../Views/Product/ProductForm";
import ProductSettings from "../Views/Settings/ProductSettings";
import TimeSheetSettings from "../Views/Settings/TimeSheetSettings";
import TimeSheetPage from "../Views/TimeSheet";
import TimeSheetApprovalPage from "../Views/TimeSheet/TimeSheetApprovals";
import TimesheetApprovalView from "../Views/TimeSheet/TimeSheetApprovals/timeSheetApprovalView";
import TimeoffPage from "../Views/TimeSheet/TimeOffRequest";
import ProductGroupPage from "../Views/Product/productGroup";
import ProductGroupForm from "../Views/Product/productGroup/productGroupform";
import ReportsMainPage from "../Views/Reports";
import ScheduledPage from "../Views/Reports/ReportAndAnalytics/scheduledReports";
import JobPage from "../Views/Job";
import JobForm from "../Views/Job/NewJob";
import JobviewPage from "../Views/Job/JobView";
import MaterialRequestPage from "../Views/MaterialRequest";
import MaterialRequestForm from "../Views/MaterialRequest/materialRequestForm";
import ProposalPage from "../Views/Proposal";
import ProposalForm from "../Views/Proposal/proposalForm";
import ProposalView from "../Views/Proposal/proposalView";
import QuotaionView from "../Views/Quotation/quotationView";
import PoForm from "../Views/PurchaseOrder/poForm";
import ProcessLossPage from "../Views/ProcessLoss";
import ProcessLossForm from "../Views/ProcessLoss/processLossForm";
import PerformanceInvoicePage from "../Views/PerformanceInvoice";
import PerformanceInvoiceForm from "../Views/PerformanceInvoice/performanceInvoiceForm";
import SalesOrderviewPage from "../Views/SalesOrder/SalesOrderView";
import ProductionSchedulePage from "../Views/ProductionSchedule";
import ProductionScheduleForm from "../Views/ProductionSchedule/ScheduleForm";
import AllProductionListPage from "../Views/ProductionSchedule/allProductionList";
import ProductionOrder from "../Views/ProductionOrder";
import ProductionProject from "../Views/ProductionOrder/productionsProject";
import ProjectsProductionForm from "../Views/ProductionOrder/projectsProductionForm";
import AllProductionOrderPage from "../Views/ProductionOrder/allProductionOrder";
import DesignPage from "../Views/Design";
import DesignForm from "../Views/Design/designForm";
import BomForm from "../Views/Design/newBom";
import BomPage from "../Views/Design/BomList";
import SalesOrderForm from "../Views/SalesOrder/newSalesOrder";
import SalesOrderPage from "../Views/SalesOrder";
import PurchaseOrderView from "../Views/PurchaseOrder/purchaseOrderView";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route>
          <Route path="/" element={<Login />} />
          <Route path="/forgotpassword" element={<Forgotpassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/layout" element={<Layout />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/dashboard" element={"dashboard"} />
          <Route path="/production" element={"production"} />
          <Route path="/purchase" element={<PurchasePage />} />
          <Route path="/purchase/form" element={<PoForm />} />

          <Route path="/customer" element={<CustomerPage />} />
          <Route path="/customer/customerform" element={<CustomerForm />} />
          <Route path="/quotation" element={<QuotationPage />} />
          <Route path="/quotation/quotationform" element={<QuotationForm />} />
          <Route path="/quotation/view" element={<QuotaionView />} />

          <Route path="/invoice" element={<InvoicePage />} />
          <Route path="/invoice/invoiceform" element={<InvoiceForm />} />
          <Route path="/invoice/view" element={<InvoiceView />} />
          <Route path="/proposal" element={<ProposalPage />} />
          <Route path="/proposal/proposalform" element={<ProposalForm />} />
          <Route path="/proposal/view" element={<ProposalView />} />


         
          <Route path="/settings" element={<SettingPage />} />
          <Route
            path="/settings/holidaysettings"
            element={<HolidaySettingsPage />}
          />
          <Route path="/settings/leadsettings" element={<LeadSettings />} />
          <Route path="/timesheet" element={<TimeSheetPage />} />
          <Route
            path="/timesheet/timesheetapproval"
            element={<TimeSheetApprovalPage />}
          />
          <Route
            path="/timesheet/approvalview"
            element={<TimesheetApprovalView />}
          />
          <Route path="/timesheet/timeoffpage" element={<TimeoffPage />} />

          <Route
            path="/settings/timesheetsettings"
            element={<TimeSheetSettings />}
          />
          <Route
            path="/settings/quoteandinvoicesettings"
            element={<QuoteAndInvoiceSettings />}
          />
          <Route
            path="/settings/accountsettings"
            element={<AccountSetting />}
          />
          <Route path="/settings/customroles" element={<CustomRolesPage />} />
          <Route path="/settings/editroles" element={<EditCustomRole />} />
          <Route path="/employee" element={<EmployeePage />} />
          <Route path="/employee/employeeform" element={<EmployeeForm />} />
          <Route path="/employee/teams" element={<Team />} />
          <Route path="/employee/teamform" element={<TeamForm />} />
          <Route
            path="/employee/customsettings"
            element={<EmployeeFieldsSettings />}
          />
          <Route
            path="/employee/shiftmanagement"
            element={<ShiftManagementPage />}
          />
          <Route path="/employee/view" element={<EmployeeViewPage />} />
          <Route path="/lead" element={<LeadPage />} />
          <Route path="lead/leadform" element={<LeadForm />} />
          <Route path="lead/leadview" element={<LeadViewPage />} />
          <Route path="/product" element={<ProductPage />} />
          <Route path="/product/productgroup" element={<ProductGroupPage />} />
          <Route
            path="/product/productgroupform"
            element={<ProductGroupForm />}
          />
          <Route path="/product/productform" element={<ProductForm />} />
          <Route path="/settings/product" element={<ProductSettings />} />
          <Route path="/report" element={<ReportsMainPage />} />
          <Route path="/report/scheduledreport" element={<ScheduledPage />} />
          <Route path="/job" element={<JobPage />} />
          <Route path="/jobform" element={<JobForm />} />
          <Route path="/jobview" element={<JobviewPage />} /> 
          <Route path="/materialrequest" element={<MaterialRequestPage />} /> 
          <Route path="/materialrequest/materialrequestform" element={<MaterialRequestForm />} /> 
          <Route path="/processloss" element={<ProcessLossPage />} />
          <Route path="/processloss/form" element={<ProcessLossForm />} />
          <Route path="/performanceinvoice" element={<PerformanceInvoicePage />} />
          <Route path="/performanceinvoice/form" element={<PerformanceInvoiceForm />} />
          <Route path="/salesorderviewpage" element={<SalesOrderviewPage />} />
          <Route path="/salesorder" element={<SalesOrderPage />} />

          <Route path="/productionschedulepage" element={<ProductionSchedulePage />} />
          <Route path="/productionschedulepage/form" element={<ProductionScheduleForm />} />
          <Route path="/allproductionschedulepage" element={<AllProductionListPage />} />
          <Route path="/allproductionOrderpage" element={<AllProductionOrderPage />} />

          <Route path="/productionorder" element={<ProductionOrder />} />
          <Route path="/ProductionProject" element={<ProductionProject />} />

          <Route path="/productionorderproject/form" element={<ProjectsProductionForm />} />
          <Route path="/desgin" element={<DesignPage />} />
          <Route path="/desgin/form" element={<DesignForm />} />
          <Route path="/bom/form" element={<BomForm />} />
          <Route path="/bom" element={<BomPage />} />
          <Route path="/salesOrderForm" element={<SalesOrderForm />} />
          <Route path="/purchaseorderview" element={<PurchaseOrderView />} />






          

</Route>
      </Routes>
    </BrowserRouter>
  );
};
export default Router;