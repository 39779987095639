import React from "react";
import CustomInput from "../../../Components/Atoms/CustomInput";
import CustomCancelSubmit from "../../../Components/Atoms/CustomCancelSubmit";

export default function JobSchedule({}) {
  return (
    <form
      // onSubmit={formik.handleSubmit}
      className="overflow-auto max-h-[64vh] py-0"
    >
      <div className="flex flex-col">
        <div className="flex gap-6">
          <div className="flex items-end gap-4 flex-grow">
            <div className="w-[70%]">
              <CustomInput
                margin="true"
                labelText={`Start Time`}
                name="scheduled_start_time"
                placeholder={"Pick Date"}
                type="date"
              />
            </div>
            <div className="w-[30%]">
              <CustomInput
                margin="true"
                labelText=""
                name="start_time"
                placeholder={"time"}
                type="time"
              />
            </div>
          </div>
        </div>

        <p className="text-[#CF0404] mb-8 mt-0">
          This follow-up is due by 2024/01/16, Wednesday
        </p>

        <CustomCancelSubmit cancelLabel="Close" submitLabel={"Schedule"} />
      </div>
    </form>
  );
}
