import AddIcon from "@mui/icons-material/Add";
import React, { useCallback, useState } from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SubHeader from "../../Components/Molecules/Subheader";
import FilterSortby from "../../Components/Molecules/FilterSortBy";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TableButton from "../../Components/Atoms/TableButton";
import { TableIconConstant } from "../../constants";

const MaterialRequestPage = () => {
    const navigate=useNavigate()
     const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const onClickFilter = () => {
    handleOpen();
  };
   
  const [page, setPage] = useState(1);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");

  const sortList = [
    { name: "Created Date", value: "createdAt-ASC" },
    { name: "Created Date", value: "createdAt-DSC" },
    { name: "Name-ASC", value: "name-ASC" },
    { name: "Name-DSC", value: "name-DSC" },
  ];
    
  

  return (
      <>
          
     
      <div className="">
     <SubHeader
          headings={[
            {
              name: "Production",
                      },
                {
              name: "Material  Request",
            },
          ]}
          actions={[
 
            {
              name: "New",
            onclick: () => {
                navigate("/materialrequest/materialrequestform");
              },
              icon: (
                <AddIcon
                   
                  sx={{ fontSize: "15px" }}
                />
              ),
              type: "button",
              },
              {
                type: "menu_drop_down",
              
                children: [
                  {
                    label:  'Convert to Job Card',
                    
                     
                  },
                  {
                    label:   'Clone MIR',
                    
                     
                    }, {
                      label:"Share MIR",
                    },  
                ],
                name:  "More",
                icon: (
                  <KeyboardArrowDownIcon
                     
                    sx={{ fontSize: "18px", marginLeft: "8px" }}
                  />
                ),
             
              },
          ]}
              />
          

        <>
          <FilterSortby
            exportBtn={false}
            totalPage={"3"}
            getPageNumber={setPage}
            getQuickFilter={setQuickFilter}
            getSortByValue={setSortBy}
            sortList={sortList}
            roleList={[{ name: "All Roles", _id: "All Roles", disabled: true }]}
            showFilter={true}
            onClickFilter={onClickFilter}
                      
          />
          <div className="overflow-auto">
            <table className="bg-[#ffffff] border border-gray-300 border-collapse w-full">
              <tbody>
                <tr className="border-b border-gray-300">
                  <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"request no #"}
                  </th>
                  <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"Project"}
                  </th>
                  <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"requestor"}
                  </th>
                  <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"Materials"}
                  </th>
                  <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"warehouse"}
                  </th>
                  <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"work center"}
                  </th>
                  <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"Request date"}
                  </th>

                  <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"issue date"}
                                  </th>
                                   <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"priority"}
                                  </th>
                                  <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"Status"}
                                  </th>
                    <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"Delivery"}
                                  </th>
                                    <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"Actions"}
                  </th>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-primColor py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    PU-5901
                  </td>
                   
                  
                 
                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    Business
                                  </td>
                                                    <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    Business
                  </td>                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    Business
                  </td>                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    Business
                  </td>                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    Business
                  </td>                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    Business
                  </td>                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    Business
                  </td>
<td className="text-sm font-medium leading-4 tracking-normal text-left text-[#0071B2] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    <button className="bg-[#DCF2FF] py-2 px-4 rounded-md">
                      Medium
                    </button>
                  </td>
                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#CF7B04] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    <button className="bg-[#FFE9D6] py-2 px-3 rounded-2xl">
                      Pending
                    </button>
                  </td>
                  

                <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#CF7B04] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    <button className="bg-[#FFE9D6] py-2 px-3 rounded-2xl">
                      Pending
                    </button>
                 </td>
                    <td className="text-[13px] font-medium leading-4 tracking-normal text-left text-[#474747] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                  <div className="flex gap-2">                    
                      <TableButton
                        size={"small"}
                        buttonType={TableIconConstant.view}
                        
                      />                    
                      <TableButton
                        size={"small"}
                        buttonType={TableIconConstant.edit}
                       
                      />                
                  </div>
                </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      </div>{" "}
    </>
  );
};

export default MaterialRequestPage;
