import React, { useCallback, useState } from "react";
import { Add } from "@mui/icons-material";
import SubHeader from "../../Components/Molecules/Subheader";
import CustomTable from "../../Components/Molecules/CustomTable";
import FilterSortby from "../../Components/Molecules/FilterSortBy";
import { useNavigate } from "react-router-dom";
import FilterWrapper from "../../Components/Molecules/FilterWrapper";
import EmployeeFilter from "./EmployeeFilter";

const EmployeePage = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const onClickFilter = () => {
    handleOpen();
  };
  const navigate = useNavigate();
  const data = [
    {
      siNo: 1,
      questionGroup: "Group 1",
      noOfQuestions: 5,
      notifyEndDate: "22.07.2024",

      answeredStatus: "Answered",
      reviewedStatus: "Reviewed",
      status: "Active",
    },
    {
      siNo: 2,
      questionGroup: "Group 2",
      noOfQuestions: 10,
      notifyEndDate: "22.07.2024",
      answeredStatus: "Unanswered",
      reviewedStatus: "Not Reviewed",
      status: "Inactive",
    },
  ];
  const [page, setPage] = useState(1);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");

  const sortList = [
    { name: "Created Date", value: "createdAt-ASC" },
    { name: "Created Date", value: "createdAt-DSC" },
    { name: "Name-ASC", value: "name-ASC" },
    { name: "Name-DSC", value: "name-DSC" },
  ];

  return (
    <>
  
      <FilterWrapper
        open={open}
        handleClose={handleClose}
        title="Filter"
      >
        <EmployeeFilter />
      </FilterWrapper>
      <div className="">
        <SubHeader
          headings={[
            {
              name: "Employees",
            },
          ]}
          actions={[
            {
              type: "button",
              name: "Manage Team",

              onclick: () => {
                navigate("/employee/teams");
              },
            },
            {
              type: "button",
              name: "Manage Shift",

              onclick: () => {
                navigate("/employee/shiftmanagement");
              },
            },
            {
              type: "button",
              name: "New Employee",

              onclick: () => {
                navigate("/employee/employeeform");
              },
              icon: (
                <Add
                  sx={{
                    fontSize: "18px",
                    marginRight: "5px",
                    color: "#7F7F7F",
                  }}
                />
              ),
            },
          ]}
        />
        <div>
          <FilterSortby
            showFilter={true}
            exportBtn={false}
            totalPage={"3"}
            getPageNumber={setPage}
            getQuickFilter={setQuickFilter}
            getSortByValue={setSortBy}
            sortList={sortList}
            roleList={[{ name: "All Roles", _id: "All Roles", disabled: true }]}
            onClickFilter={onClickFilter}
          />
          <div className="overflow-auto">
            <CustomTable
              tableTitle={[
                "EMPLOYEE CODE",
                "NAME",
                "DESCRIPTION",
                "ROle",
                "PHONE NUMBER",
                "CREATED ON",
                "STATUS",
                "ACTIONS",
              ]}
              navigateView={() => navigate("/employee/view")}
              tableData={data}
              viewButton={true}
              editButton={true}
              deleteButton={false}
            />
          </div>
          
        </div>
      </div>
    </>
  );
};

export default EmployeePage;

 