import React from "react";
import Whitespace from "../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../Components/Atoms/ComponentTitle";
import SubHeader from "../../Components/Molecules/Subheader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const PurchaseOrderView = () => {
  return (
      <>
            <SubHeader
          headings={[
            {
              name: "Purchase Order",
                  }, {
                name:"Sample Order View",
            }
          ]}
          actions={[
              {
                type: "menu_drop_down",
              
                children: [
                  {
                    label:  'Mark as Accepted',
                    
                     
                  },
                  {
                    label:   'Mark as Declined',
                    
                     
                    }, {
                      label:"Convert to Sales Order",
                    }, {
                      label:"Clone Purchase Order",
                    }, {
                      label:"Share Purchase Order"
                    }, {
                      label:"Delete Purchase Order"
                  }
                ],
                name:  "More",
                icon: (
                  <KeyboardArrowDownIcon
                     
                    sx={{ fontSize: "16px", marginLeft: "8px" }}
                  />
                ),
             
              },
          ]}
        />
      <Whitespace mt="23px" className={"px-7 py-7 w-full"}>
        <ComponentTitle title="Purchase order details" />
        <p className="text-[#8F8F8F] text-[16px] font-medium mb-4">
          Primary Details
        </p>
        <div className="columnFour">
          <div>
            <ComponentTitle title="PO Title" mb="0" />
            <p className="text-[12px]">PO  title</p>
          </div>
          <div>
            <ComponentTitle title="PO Category" mb="0" />
            <p className="text-[12px]">new category</p>
          </div>
          <div>
            <ComponentTitle title="PO Priority" mb="0" />
            <p className="text-[12px]">priority 1</p>
          </div>
          <div>
            <ComponentTitle title="PO Type" mb="0" />
            <p className="text-[12px]">new type</p>
          </div>
        </div>

        <p className="text-[#8F8F8F] text-[16px] font-medium mb-4 mt-8">
          Customer Details
        </p>
        <div className="columnFour">
          <div>
            <ComponentTitle title="Customer Summary" mb="0" />
            <p className="text-[12px]">Name</p>
            <p className="text-[12px]">Address</p>
            <p className="text-[12px]">Mobile / contact</p>
          </div>
          <div>
            <ComponentTitle title="Service Contact Info" mb="0" />
            <p className="text-[12px]">Name</p>
            <p className="text-[12px]">Address</p>
            <p className="text-[12px]">Mobile / contact</p>
          </div>
          <div>
            <ComponentTitle title="Billing Contact Info" mb="0" />
            <p className="text-[12px]">Name</p>
            <p className="text-[12px]">Address</p>
            <p className="text-[12px]">Mobile / contact</p>
          </div>
          <div></div>
              </div>
              
               <p className="text-[#8F8F8F] text-[16px] font-medium mb-4 mt-8">
          Product/Service Details
        </p>
        <div className="columnFour">
          <div>
            <ComponentTitle title="PO Id" mb="0" />
            <p className="text-[12px]">123455</p>
          </div>
          <div>
            <ComponentTitle title="Assigned User/Team" mb="0" />
            <p className="text-[12px]">Pavithraa</p>
          </div>
          <div>
            <ComponentTitle title="PO Description" mb="0" />
            <p className="text-[12px]">The description</p>
          </div>
          <div>
            <ComponentTitle title="PO Attachments" className="mb-0" />
            <p className="text-[12px]">Sample Attachments</p>
          </div>
        </div>

        <p className="text-[#8F8F8F] text-[16px] font-medium mb-4 mt-8">
          Other Details
        </p>
        <div className="columnFour">
          <div>
            <ComponentTitle title="Project Id" mb="0" />
            <p className="text-[12px]">123455</p>
          </div>
          <div>
            <ComponentTitle title="Shipment Tracking Info" mb="0" />
            <p className="text-[12px]">Pavithraa</p>
          </div>
          <div>
            <ComponentTitle title="Poc Phone Number" mb="0" />
            <p className="text-[12px]">The description</p>
          </div>
          <div>
            <ComponentTitle title="Additional Input" className="mb-0" />
            <p className="text-[12px]">Sample Attachments</p>
          </div>
              </div>
              

                 <p className="text-[#8F8F8F] text-[16px] font-medium mb-4 mt-8">
          Additional Details
        </p>
        <div className="columnFour">
          <div>
            <ComponentTitle title="Project Id" mb="0" />
            <p className="text-[12px]">123455</p>
          </div>
          <div>
            <ComponentTitle title="Shipment Tracking Info" mb="0" />
            <p className="text-[12px]">Pavithraa</p>
          </div>
          <div>
            <ComponentTitle title="Poc Phone Number" mb="0" />
            <p className="text-[12px]">The description</p>
          </div>
          <div>
            <ComponentTitle title="Additional Input" className="mb-0" />
            <p className="text-[12px]">Sample Attachments</p>
          </div>
        </div>
      </Whitespace>
    </>
  );
};

export default PurchaseOrderView;
