import React from "react";
import Header from "../Header";
import Navbar from "../Navbar";
import { Outlet } from "react-router-dom";
const Layout = (props) => {
  return (
    <>
      <div className="">
        <Header />
        <Navbar />
        <div className="w-full bg-[#F7FAFF]">
          <div className="h-[calc(100vh-188px-10px)] overflow-auto px-[105px] pb-4">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
