
import MenuDropdown from "../../Atoms/MenuDropDown";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const SubHeaderMenu = (props) => {

  
  console.log(props,"proposs")
  return (
    <MenuDropdown
      menuItems={props.each.children}
       padding="px-2"
                right="right-1"
                minWidth="min-w-52"
    >
      <div className="text-[14px] border rounded  border-[#B1B1B1] text-[#757575] font-[300] px-2 py-[2px] -mt-1 flex items-center gap-1">
         <p className="text-[14px] text-[#757575] font-[300]">        
        {props.each.name}      
      </p>
        <KeyboardArrowDownIcon sx={{ fontSize: "17px" }} />
        </div>

    </MenuDropdown>
  );
};

export default SubHeaderMenu;
