import React from "react";
import CustomSelect from "../../Components/Atoms/CustomSelect";
import CustomInput from "../../Components/Atoms/CustomInput";
import SearchIcon from "@mui/icons-material/Search";



const EmployeeFilter = () => {
  return (
    <>
      <div className="-ml-2 mb-[610px]">
          {/* <div className="flex items-center bg-[#F6F6F6] p-2 rounded-md mr-5">
            <input
              data-testid="search-field-header"
              // onChange={(e) => dispatch(setSearchInput(e.target.value))}
              className="w-full lg:w-64 h-8 bg-transparent outline-none"
              id="search-input-field"
              placeholder="Search"
              type="search"
            />
            <button
              data-testid="search-button-header"
              onClick={() => {}}
              className="bg-transparent border-0 outline-none"
            >
              <SearchIcon
                sx={{ fontSize: "25px" }}
                className="h-5 w-5 text-primColor"
              />
            </button>
          </div> */}
        <CustomSelect
          labelText={"FILTER BY EMPLOYEE ROLE"}
          placeholder={"Choose Any Role"}
         
        />
         <CustomInput
          labelText={"FILTER BY DATE"}
          placeholder={"Choose Any Role"}
          type={"date"}
        />
         <CustomSelect
          labelText={"FILTER BY STATUS"}
          placeholder={"Pick an option"}
          
        />
      </div>
    </>
  );
};

export default EmployeeFilter;
