import { useRef, useState } from "react";
import CustomInput from "../../Components/Atoms/CustomInput";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SubHeader from "../../Components/Molecules/Subheader";
import CustomSelect from "../../Components/Atoms/CustomSelect";
import CustomButton from "../../Components/Atoms/CustomButton";
import ComponentTitle from "../../Components/Atoms/ComponentTitle";
import { TableIconConstant } from "../../constants";
import Whitespace from "../../Components/Atoms/WhiteSpace";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";

import TextArea from "../../Components/Atoms/TextArea";

const ProposalForm = (props) => {
  const [preview, setPreview] = useState(true);
  const handleFilesChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const obj = {
        file_name: e.target.files[i].name,
        url: "",
        created_by: props.userId,
        attachment: e.target.files[i],
      };
    }
  };
  const hiddenFileInput = useRef(null);
  const handleUploadBtn = () => {
    hiddenFileInput?.current?.click();
  };
  return (
    <>
      <div className="main-container">
        <SubHeader
          headings={[
            {
              name: `Proposal`,
              url: "/proposal",
            },
            {
              name: `New Proposal`,
            },
          ]}
          actions={[
            {
              type: "button",

              name: "Save As Draft",

              icon: (
                <DraftsIcon
                  
                  sx={{ fontSize: "15px" }}
                />
              ),
            },

            {
              type: "button",
              name: "Save and Send",
              icon: <SendIcon sx={{ fontSize: "15px" }} />,
            },
          ]}
        />
     

        <div className="flex relative gap-5">
          <div
            className="w-[50%] overflow-auto mt-7"
            style={preview ? null : { width: "96%" }}
          >
            <Whitespace>
              <ComponentTitle title={"BILLING CONTACT INFO"} />

              <div className="columnTwo">
                <CustomInput
                  labelText={"Contact First Name"}
                  setValue={"_id"}
                  name="organization"
                  placeholder={"Enter contact first name"}
                />
                <CustomInput
                  labelText={"Contact Last Name"}
                  setValue={"_id"}
                  setOption={"first_name"}
                  name="customer"
                  placeholder={"Enter contact last name"}
                />
              </div>
              <div className="columnTwo">
                <CustomInput
                  labelText={"Contact Email"}
                  placeholder={"Enter contact email"}
                  data={[]}
                />
                <CustomInput
                  labelText={"Contact Phone Number"}
                  setValue={"_id"}
                  setOption={"title"}
                  placeholder={"Enter contact phone number"}
                  name="jobs"
                />
              </div>
              <div className="mt-4">
                <ComponentTitle title={"BILLING CONTACT INFO"} />

                <CustomInput
                  labelText={"Address"}
                  setValue={"_id"}
                  name="organization"
                  placeholder={"Enter customer address"}
                />
              </div>

              <div className="columnTwo">
                <CustomInput
                  labelText={"City"}
                  placeholder={"Enter city"}
                  data={[]}
                />
                <CustomInput
                  labelText={"Landmark"}
                  setValue={"_id"}
                  setOption={"title"}
                  placeholder={"Enter landmark"}
                  name="jobs"
                />
              </div>
              <div className="columnTwo">
                <CustomInput
                  labelText={"State / Province / Region"}
                  placeholder={"Enter state / province / region"}
                  data={[]}
                />
                <CustomInput
                  labelText={"Postal / Zipcode"}
                  setValue={"_id"}
                  setOption={"title"}
                  placeholder={"Enter postal / zipcode"}
                  name="jobs"
                />
              </div>
            </Whitespace>
            <Whitespace className={"mt-5"}>
              <ComponentTitle title="PROPOSAL DETAILS" className="mb-9" />
              <div className="columnThree">
                <CustomInput
                  margin="true"
                  name="customer_billing_address.first_name"
                  type="text"
                  labelText={"Prefix"}
                  placeholder={"ABC"}
                />
                <CustomInput
                  name="customer_billing_address.last_name"
                  type="text"
                  labelText={"Reference Number"}
                  placeholder={"Eg: 1012"}
                />
                <CustomInput
                  name="customer_billing_address.last_name"
                  labelText={"Proposal Date"}
                  type="date"
                />
              </div>

              <div className="columnThree">
                <CustomInput
                  margin="true"
                  required="true"
                  name="customer_billing_address.email"
                  type="text"
                  labelText={"Payment Term"}
                  placeholder={"Two month term"}
                />

                <CustomInput
                  labelText={"Due Date"}
                  type={"date"}
                  name="customer_billing_address.phone_number"
                />
                <CustomSelect
                  margin="true"
                  required="true"
                  name="customer_billing_address.email"
                  type="text"
                  labelText={"Quatation Template"}
                  placeholder={"Choose Template"}
                />
              </div>
              <CustomInput
                labelText={"Address"}
                setValue={"_id"}
                name="organization"
                placeholder={"Enter customer address"}
              />
              <TextArea
                name="description"
                labelText={"Note"}
                required="true"
                placeholder={"Add notes related to this Proposal"}
                type="text"
              />
            </Whitespace>

            <Whitespace className={"mt-5"}>
              <div className="flex items-center justify-between mb-10">
                <p className="font-bold text-[11px]">ITEM DETAILS</p>
                <CustomButton
                  btnType="secondary"
                  // size="medium"
                  buttonType={TableIconConstant.add}
                  mr={true}
                  //   onClick={() => handleAddCustomerOpen()}
                >
                  {"Pick Customer"}
                </CustomButton>
              </div>
              <div className="flex flex-col gap-5 justify-center items-center py-9">
                <h1 className="text-[17px] font-semibold leading-[19px] text-textColor">
                  {" Add Product"}
                </h1>
                <p className="text-sm font-normal text-[#9d9d9d]">
                  {
                    "Add any parts or services of any custom line item to this Proposal"
                  }
                </p>

                <CustomButton
                  btnType="secondary"
                  size="small"
                  buttonType={TableIconConstant.add}

                  //   onClick={() => handleAddCustomerOpen()}
                >
                  {"Add Item"}
                </CustomButton>
              </div>
            </Whitespace>
            <Whitespace className={"mt-5"}>
              <div className="flex items-center justify-between mb-10">
                <ComponentTitle
                  title={"Proposal attachments"}
                ></ComponentTitle>
                <div className="flex">
                  <CustomButton
                    size="medium"
                    buttonType={TableIconConstant.add}
                  >
                    {"Upload"}
                  </CustomButton>
                </div>
              </div>
              <input
                onChange={handleFilesChange}
                ref={hiddenFileInput}
                style={{ display: "none" }}
                type="file"
                multiple
              />
              {/* {formik.values.attachments.length === 0 ? ( */}
              <div className="flex flex-col justify-center items-center py-9">
                <h1 className="text-[17px] font-semibold leading-[19px] text-black mb-[16px]">
                  {"Upload Attachments"}
                </h1>
                <p className="text-[14px] font-normal leading-[15px] text-[#9d9d9d] mb-[25px]">
                  {
                    "Add any parts or services of any custom line item to this Proposal"
                  }
                </p>

                <CustomButton
                  size="medium"
                  onClick={handleUploadBtn}
                  buttonType={TableIconConstant.add}
                >
                  {"Upload"}
                </CustomButton>
              </div>
              {/* ) : (
              <PreviewFiles
                data={formik.values.attachments}
                removeFile={removeFile}
                show="div"
              /> */}
              {/* )} */}
            </Whitespace>
          </div>
          <div
            className="w-[50%] relative overflow-auto mt-7"
            style={
              preview
                ? {
                    minHeight: "520px",
                    backgroundColor: "#ffffff",
                    height: "fit-content",
                  }
                : {
                    width: "0%",
                    overflow: "hidden",
                    padding: "0px 0px 0px 34px",
                  }
            }
          >
            <button
              className="absolute top-[68px] left-[-36px] transform -rotate-90  border-[0.4px] border-[#d1dddb] bg-white text-[12px] px-11 font-semibold leading-[10px] tracking-[0.23em] text-left text-[#002e25] w-[72px] h-[30px] rounded-tl-none rounded-tr-[2px] rounded-br-[2px] rounded-bl-none flex justify-center items-center"
              onClick={() => setPreview(!preview)}
            >
              preview
              {preview ? (
                <KeyboardArrowDownIcon sx={{ fontSize: "12px" }} />
              ) : (
                <KeyboardArrowUpIcon sx={{ fontSize: "12px" }} />
              )}
            </button>
          </div>
        </div>
        {/* </Whitespace> */}
      </div>
    </>
  );
};

export default ProposalForm;
