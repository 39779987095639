import React from "react";
import CustomSelect from "../../Components/Atoms/CustomSelect";
import CustomInput from "../../Components/Atoms/CustomInput";


const QuotationFillter = () => {
  return (
    <>
      <div className="-ml-2 mb-[610px]">
        <CustomInput
          labelText={"FILTER BY QUOTE DATE"}           
          type={"date"}         
        />
         <CustomInput
          labelText={"FILTER BY DUE DATE"}          
          type={"date"}
        />
         <CustomInput
          labelText={"FILTER BY CUSTOMER"}
          placeholder={"Enter Name/Phone Number/Email"}          
        />

        <CustomSelect labelText={"FILTER BY ORGANIZATION"}
          placeholder={"Choose Organization Name"}
        
        />
        <CustomSelect labelText={"QUOTE STATUS"}
          placeholder={"Choose Quote Status"}
        
        />
      </div>
    </>
  );
};

export default QuotationFillter;
