import React from "react";
import Whitespace from "../../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";
import CustomInput from "../../../Components/Atoms/CustomInput";
import CustomSelect from "../../../Components/Atoms/CustomSelect";
import CustomButton from "../../../Components/Atoms/CustomButton";

const JobAssignUsers = () => {
  return (
    <>
      <Whitespace mt="23px" className={"px-7 py-7 w-full"}>
        <ComponentTitle title={"ASSIGN USERS"} />
        <div className="columnThree">
          <CustomSelect
            margin="true"
            labelText="Select Team"
            name="username"
            placeholder="Maintenance"
            type="text"
          />
          <div></div>
          <div></div>
        </div>
      </Whitespace>
      <div className="flex gap-7">
        <Whitespace mt="23px" className={"px-7 py-7 w-[50%]"}>
          <ComponentTitle title={"AVAILABLE USERS"} />
          <div className="">
            <CustomInput
              margin="true"
              name="username"
              placeholder="Search Users"
              type="text"
            />
            <div className="flex gap-2 items-center mb-7">
              <input className="h-4 w-4" type="checkbox" />
              <label
                className="login-show-pass-checkbox-label"
                htmlFor="show-password-checkbox"
              >
                Show only available users
              </label>
            </div>
            <ComponentTitle title="Admin" className="mb-0" />
            <div className="available-users">
              <div className="flex justify-between mt-4">
                <div className="flex">
                  <div className="w-12 text-[27px] text-[#ABABAB] h-12 font-bold rounded-full bg-gray-300 flex justify-center items-center mr-2.5">
                    U
                  </div>
                  <div className="flex flex-col justify-evenly">
                    <p className="text-xs font-semibold leading-3 text-left text-green-900 m-0">
                      pavi
                    </p>
                    <p className="text-xs font-medium leading-6 text-left text-green-900 m-0">
                      Skills: Skill one, Skill two
                    </p>
                    <p className="text-xs font-medium leading-3 text-left text-green-900 m-0">
                      Work Time: 12.00 to 2.00
                    </p>
                  </div>
                </div>
                <CustomButton btnType="primary">Assign</CustomButton>
              </div>
            </div>
            <div className="available-users">
              <div className="flex justify-between mt-4">
                <div className="flex">
                  <div className="w-12 text-[27px] text-[#ABABAB] h-12 font-bold rounded-full bg-gray-300 flex justify-center items-center mr-2.5">
                    U
                  </div>
                  <div clclassNameass="flex flex-col justify-evenly">
                    <p className="text-xs font-semibold leading-3 text-left text-green-900 m-0">
                      pavi
                    </p>
                    <p className="text-xs font-medium leading-6 text-left text-green-900 m-0">
                      Skills: Skill one, Skill two
                    </p>
                    <p className="text-xs font-medium leading-3 text-left text-green-900 m-0">
                      Work Time: 12.00 to 2.00
                    </p>
                  </div>
                </div>
                <CustomButton btnType="primary">Assign</CustomButton>
              </div>
            </div>
            <ComponentTitle title="TEAM LEADERS" className="mb-0 mt-7" />
            <div className="available-users">
              <div className="flex justify-between mt-4">
                <div className="flex">
                  <div className="w-12 text-[27px] text-[#ABABAB] h-12 font-bold rounded-full bg-gray-300 flex justify-center items-center mr-2.5">
                    U
                  </div>
                  <div className="flex flex-col justify-evenly">
                    <p className="text-xs font-semibold leading-3 text-left text-green-900 m-0">
                      pavi
                    </p>
                    <p className="text-xs font-medium leading-6 text-left text-green-900 m-0">
                      Skills: Skill one, Skill two
                    </p>
                    <p className="text-xs font-medium leading-3 text-left text-green-900 m-0">
                      Work Time: 12.00 to 2.00
                    </p>
                  </div>
                </div>
                <CustomButton btnType="primary">Assign</CustomButton>
              </div>
            </div>
            <div className="available-users">
              <div className="flex justify-between mt-4">
                <div className="flex">
                  <div className="w-12 text-[27px] text-[#ABABAB] h-12 font-bold rounded-full bg-gray-300 flex justify-center items-center mr-2.5">
                    U
                  </div>
                  <div className="flex flex-col justify-evenly">
                    <p className="text-xs font-semibold leading-3 text-left text-green-900 m-0">
                      pavi
                    </p>
                    <p className="text-xs font-medium leading-6 text-left text-green-900 m-0">
                      Skills: Skill one, Skill two
                    </p>
                    <p className="text-xs font-medium leading-3 text-left text-green-900 m-0">
                      Work Time: 12.00 to 2.00
                    </p>
                  </div>
                </div>
                <CustomButton btnType="primary">Assign</CustomButton>
              </div>
            </div>
          </div>
        </Whitespace>
        <Whitespace mt="23px" className={"px-7 py-7 w-[50%]"}>
          <ComponentTitle title={"ASSIGN USERS"} />
          <div className="">
            <CustomInput
              margin="true"
              name="username"
              placeholder="Search Assigned Users"
              type="text"
            />

            <ComponentTitle title="Admin" className="mb-0 mt-16" />
            <div className="available-users">
              <div className="flex justify-between mt-4">
                <div className="flex">
                  <div className="w-12 text-[27px] text-[#ABABAB] h-12 font-bold rounded-full bg-gray-300 flex justify-center items-center mr-2.5">
                    U
                  </div>
                  <div className="flex flex-col justify-evenly">
                    <p className="text-xs font-semibold leading-3 text-left text-green-900 m-0">
                      pavi
                    </p>
                    <p className="text-xs font-medium leading-6 text-left text-green-900 m-0">
                      Skills: Skill one, Skill two
                    </p>
                    <p className="text-xs font-medium leading-3 text-left text-green-900 m-0">
                      Work Time: 12.00 to 2.00
                    </p>
                  </div>
                </div>
                <CustomButton btnType="primary">Assign</CustomButton>
              </div>
            </div>
            <div className="available-users">
              <div className="flex justify-between mt-4">
                <div className="flex">
                  <div className="w-12 text-[27px] text-[#ABABAB] h-12 font-bold rounded-full bg-gray-300 flex justify-center items-center mr-2.5">
                    U
                  </div>
                  <div className="flex flex-col justify-evenly">
                    <p className="text-xs font-semibold leading-3 text-left text-green-900 m-0">
                      pavi
                    </p>
                    <p className="text-xs font-medium leading-6 text-left text-green-900 m-0">
                      Skills: Skill one, Skill two
                    </p>
                    <p className="text-xs font-medium leading-3 text-left text-green-900 m-0">
                      Work Time: 12.00 to 2.00
                    </p>
                  </div>
                </div>
                <CustomButton btnType="primary">Assign</CustomButton>
              </div>
            </div>
            <ComponentTitle title="TEAM LEADERS" className="mb-0 mt-7" />
            <div className="available-users">
              <div className="flex justify-between mt-4">
                <div className="flex">
                  <div className="w-12 text-[27px] text-[#ABABAB] h-12 font-bold rounded-full bg-gray-300 flex justify-center items-center mr-2.5">
                    U
                  </div>
                  <div className="flex flex-col justify-evenly">
                    <p className="text-xs font-semibold leading-3 text-left text-green-900 m-0">
                      pavi
                    </p>
                    <p className="text-xs font-medium leading-6 text-left text-green-900 m-0">
                      Skills: Skill one, Skill two
                    </p>
                    <p className="text-xs font-medium leading-3 text-left text-green-900 m-0">
                      Work Time: 12.00 to 2.00
                    </p>
                  </div>
                </div>
                <CustomButton btnType="primary">Assign</CustomButton>
              </div>
            </div>
            <div className="available-users">
              <div className="flex justify-between mt-4">
                <div className="flex">
                  <div className="w-12 text-[27px] text-[#ABABAB] h-12 font-bold rounded-full bg-gray-300 flex justify-center items-center mr-2.5">
                    U
                  </div>
                  <div className="flex flex-col justify-evenly">
                    <p className="text-xs font-semibold leading-3 text-left text-green-900 m-0">
                      pavi
                    </p>
                    <p className="text-xs font-medium leading-6 text-left text-green-900 m-0">
                      Skills: Skill one, Skill two
                    </p>
                    <p className="text-xs font-medium leading-3 text-left text-green-900 m-0">
                      Work Time: 12.00 to 2.00
                    </p>
                  </div>
                </div>
                <CustomButton btnType="primary">Assign</CustomButton>
              </div>
            </div>
          </div>
        </Whitespace>
      </div>
    </>
  );
};

export default JobAssignUsers;
