import React, { useState } from "react";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomInput from "../../../Components/Atoms/CustomInput";

export default function JobAssignment({}) {
  const [searchTeam, setSearchTeam] = useState("");
  const [searchUser, setSearchUser] = useState("");

  const [currTeam, setCurrTeam] = useState("All teams");

  return (
    <div className="flex gap-3 jobAssignment">
      <div className="flex-grow">
        <CustomInput
          margin="true"
          value={searchTeam}
          onChange={(e) => setSearchTeam(e.target.value)}
          labelText={"Your Teams"}
          placeholder={"search teams"}
          type="text"
        />
        <ul className="list-none pl-0">
          <li
            // onClick={() => {
            //   setCurrTeam(each.name);
            // }}
            className="flex p-3 items-center justify-between cursor-pointer border-t"
          >
            <p
            // className={
            //   currTeam === each.name
            //     ? "pl-2 text-teal-700"
            //     : ""
            // }
            >
              {"name"}
            </p>
            <KeyboardArrowRightIcon className="w-3.5" />
          </li>
          <li
            // onClick={() => {
            //   setCurrTeam(each.name);
            // }}
            className="flex p-3 items-center justify-between cursor-pointer border-t"
          >
            <p
            // className={
            //   currTeam === each.name
            //     ? "pl-2 text-teal-700"
            //     : ""
            // }
            >
              {"name"}
            </p>
            <KeyboardArrowRightIcon className="w-3.5" />
          </li>
          <li
            // onClick={() => {
            //   setCurrTeam(each.name);
            // }}
            className="flex p-3 items-center justify-between cursor-pointer border-t"
          >
            <p
            // className={
            //   currTeam === each.name
            //     ? "pl-2 text-teal-700"
            //     : ""
            // }
            >
              {"name"}
            </p>
            <KeyboardArrowRightIcon className="w-3.5" />
          </li>{" "}
          <li
            // onClick={() => {
            //   setCurrTeam(each.name);
            // }}
            className="flex p-3 items-center justify-between cursor-pointer border-t"
          >
            <p
            // className={
            //   currTeam === each.name
            //     ? "pl-2 text-teal-700"
            //     : ""
            // }
            >
              {"name"}
            </p>
            <KeyboardArrowRightIcon className="w-3.5" />
          </li>
        </ul>
      </div>
      <div className="border-l h-[90vh]"></div>
      <div className="flex-grow">
        <CustomInput
          margin="true"
          value={searchUser}
          onChange={(e) => setSearchUser(e.target.value)}
          labelText={"Search Users"}
          placeholder={"search users"}
          type="text"
        />
        <div className="flex justify-between items-center mb-7">
          <p className="text-sm font-medium leading-[13px]">143 {"users"}</p>
          <div>
            <CustomButton
              size="medium"
              btnType="primary"
              className="cursor-pointer bg-teal-700 rounded text-[11px] font-medium leading-[15px] text-white border-0 px-2 py-1.5 min-w-[60px]"
              type="button"
            >
              {"Assign All"}
            </CustomButton>
          </div>
        </div>
        <ul className="list-none pl-0">
          <li className="flex justify-between items-center">
            <div className="flex mb-5">
              <div className="w-10 text-[17px]  h-10 font-bold rounded-full bg-gray-300 flex justify-center items-center mr-2.5">
                U
              </div>
              <div className="flex flex-col justify-evenly">
                <p className="text-xs font-semibold leading-3 text-left  m-0">
                  pavi
                </p>
                <p className="text-xs font-medium leading-6 opacity-80 text-left m-0">
                  Skills: Skill one, Skill two
                </p>
              </div>
            </div>

            <CustomButton
              btnType="primary"
              size="medium"
              className="cursor-pointer bg-teal-700 rounded text-[11px] font-medium leading-[15px] text-white border-0 px-2 py-1.5 min-w-[60px]"
              type="button"
            >
              {"assign"}
            </CustomButton>
          </li>
          <li className="flex justify-between items-center">
            <div className="flex mb-5">
              <div className="w-10 text-[17px]  h-10 font-bold rounded-full bg-gray-300 flex justify-center items-center mr-2.5">
                U
              </div>
              <div className="flex flex-col justify-evenly">
                <p className="text-xs font-semibold leading-3 text-left  m-0">
                  pavi
                </p>
                <p className="text-xs font-medium leading-6 opacity-80 text-left m-0">
                  Skills: Skill one, Skill two
                </p>
              </div>
            </div>

            <CustomButton
              btnType="primary"
              size="medium"
              className="cursor-pointer bg-teal-700 rounded text-[11px] font-medium leading-[15px] text-white border-0 px-2 py-1.5 min-w-[60px]"
              type="button"
            >
              {"assign"}
            </CustomButton>
          </li>{" "}
          <li className="flex justify-between items-center">
            <div className="flex mb-5">
              <div className="w-10 text-[17px]  h-10 font-bold rounded-full bg-gray-300 flex justify-center items-center mr-2.5">
                U
              </div>
              <div className="flex flex-col justify-evenly">
                <p className="text-xs font-semibold leading-3 text-left  m-0">
                  pavi
                </p>
                <p className="text-xs font-medium leading-6 opacity-80 text-left m-0">
                  Skills: Skill one, Skill two
                </p>
              </div>
            </div>

            <CustomButton
              btnType="primary"
              size="medium"
              className="cursor-pointer bg-teal-700 rounded text-[11px] font-medium leading-[15px] text-white border-0 px-2 py-1.5 min-w-[60px]"
              type="button"
            >
              {"assign"}
            </CustomButton>
          </li>
        </ul>
      </div>
      <div className="border-l h-[90vh]"></div>
      <div className="flex-grow-[3]">
        <p className="">{"Currently Assigned"}</p>
        <ul className="list-none pl-0">
          <li className="flex justify-between items-center mb-2.5 mt-5">
            <div className="flex mb-5">
              <div className="w-10 text-[17px]  h-10 font-bold rounded-full bg-gray-300 flex justify-center items-center mr-2.5">
                U
              </div>
              <div className="flex flex-col justify-evenly">
                <p className="text-xs font-semibold leading-3 text-left  m-0">
                  pavi
                </p>
                <p className="text-xs font-medium leading-6 opacity-80 text-left m-0">
                  Skills: Skill one, Skill two
                </p>
              </div>
            </div>
            <CustomButton
              btnType="primary"
              size="medium"
              className="cursor-pointer bg-red-600 rounded text-[11px] font-medium leading-[15px] text-white border-0 px-2 py-1.5 min-w-[60px]"
              type="button"
            >
              {"unassign"}
            </CustomButton>
          </li>
        </ul>
      </div>
    </div>
  );
}
