import React, { useRef, useState } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import GroupIcon from "@mui/icons-material/Group";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";
import CustomButton from "../../../Components/Atoms/CustomButton";
import Data_Not_Found from "../../../Assets/Data_Not_Found.svg";

import TextArea from "../../../Components/Atoms/TextArea";

export default function JobNotes(props) {
  const {} = props;

  const hiddenFileInputSingle = useRef(null);
  const [clearCkedit, setClearCkedit] = useState();
  let [addedfilename, setAddfilename] = useState();

  const handleUploadSingle = (event) => {
    event.preventDefault();
    hiddenFileInputSingle.current.click();
  };

  const handleChange = (e) => {
    setAddfilename(e?.currentTarget?.files[0]?.name);
    const filename = e?.currentTarget?.files[0]?.name?.split(".");
    if (filename && filename[0]) {
      const format = filename[filename?.length - 1];
  
    }
  };

  return (
    <>
      <div className="">
        <ComponentTitle title={"Lead NOTES"} mt="5" />

        <>
          <div className="p-2 border border-solid border-gray-300">
            <div className="flex justify-between items-center">
              <div className="flex mt-2">
                <div className="w-10 text-[17px]  h-10 font-bold rounded-full bg-gray-300 flex justify-center items-center mr-2.5">
                  P
                </div>
                <div className="flex flex-col justify-evenly">
                  <p className="text-[14px] font-medium leading-3 text-left  m-0">
                    Pavi
                  </p>
                  <p className="text-xs font-medium leading-6 opacity-80 text-left m-0">
                    FrontEnd Developer
                  </p>
                </div>
              </div>

              <select
                name="type"
                defaultValue={"Internel"}
                className="p-2 border border-solid border-gray-300 rounded-md"
              >
                <option value={"Internel"}>
                  <GroupIcon className="mr-1" /> {"internal"}
                </option>
                <option value={"Externel"}>
                  <GroupIcon className="mr-1" /> {"external"}
                </option>
              </select>
            </div>
            <div className="mt-6">
              <TextArea placeholder={"Enter Description"} />
            </div>

            <div className="flex justify-between items-center my-1">
              <input
                type="file"
                onChange={handleChange}
                ref={hiddenFileInputSingle}
                style={{ display: "none" }}
                // accept="image/jpg,image/png,image/jpeg"
              />
              <button
                onClick={handleUploadSingle}
                className="flex items-center bg-transparent text-sm border-0 text-[#140C00]"
              >
                <AttachFileIcon
                  className=""
                  sx={{ fontSize: "17px", marginRight: "4px" }}
                />
                {addedfilename ? addedfilename : "Attach Files"}
              </button>

              <div className="self-end">
                <CustomButton size="large" btnType="primary">
                  {"Post Note"}
                </CustomButton>
              </div>
            </div>
          </div>

          <div className="p-8 h-48 overflow-auto">
            {/* <div className="flex items-start mb-4">
              <div className="w-4 h-4 bg-gray-300 rounded-full"></div>
              <p className="text-sm text-gray-500 ml-2">createdAt</p>
              <div className="ml-4">
                <p className="text-sm">
                  <span className="font-medium">first_name</span> content
                </p>
                <button className="mt-2">
                  <a className="text-blue-500" target="_blank">
                    {"Attach Files"}
                  </a>
                </button>
              </div>
            </div> */}
            <div className="flex flex-col gap-3 items-center justify-center h-full">
              <div className="w-11 h-11">
                <img className="" src={Data_Not_Found} alt="no data" />
              </div>
              <h6 className="text-sm font-medium">{"No Notes Found"}</h6>
            </div>{" "}
          </div>
        </>
      </div>
    </>
  );
}
