import React from "react";
import CustomDialog from "../CustomDialog";
import CustomCancelSubmit from "../../Atoms/CustomCancelSubmit";
import CustomButton from "../../Atoms/CustomButton";

export default function FilterWrapper({
  children,
  open,
  handleClose,
  title = "Filter",
  onSubmit,
  onReset,
  applyFilterLoading = false,
}) {
  return (
    <CustomDialog
      width="19%"
      minWidth="260px"
      height="100%"
      maxHeight="100%"
      right="0px"
      position="relative"
      left="none"
      handleClose={handleClose}
      open={open}
      Title={title}
      innerHeight="100vh"
      titleMt=""
    >
      <form onSubmit={onSubmit} className="">
        {children}
      </form>
      <div className="flex justify-center">
        <CustomCancelSubmit
          cancelLabel="ResetFilter"
          submitLabel={applyFilterLoading ? "ApplyingFilter..." : "ApplyFilter"}
          handleClose={() => {
            handleClose();
            onReset();
          }}
          handleSubmit={() => {
            handleClose();
            onSubmit();
          }}
        />
        

      </div>
    </CustomDialog>
  );
}
