import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import SubHeader from "../../../Components/Molecules/Subheader";
import CustomersDetails from "./customerDetails";
import JobDetails from "./JobDetails";
import JobAssignUsers from "./AssignUser";
import PreviewAndSave from "./PreviewAndSave";
 

const JobForm = () => {
  const tabConstant = {
    customerDetails: "Customer Details",
    jobDetails: "Job Details",
    assignDetails: "Assign Details",
    previewAndSave: "Preview and Save",
  };
  const navigate = useNavigate();
  

  const [checkphone, setCheckphone] = useState(0);

  const [currentTab, setCurrentTab] = useState(tabConstant.customerDetails);
  const activeCon =
    "w-7 h-7 text-[10px] rounded-full bg-gray-300 mr-1 cursor-pointer w-7 h-7 rounded-full  bg-primColor text-primColor flex justify-center items-center";
  const activePara =
    "text-[10px] font-semibold leading-[11px] tracking-normal text-left mr-2";
  const errorCon =
    "w-7 h-7 text-[19px] rounded-full bg-gray-300 mr-1 cursor-pointer w-7 h-7 rounded-full bg-primColor text-primColor flex justify-center items-center";
  const errorPara =
    "text-[10px] font-semibold leading-[11px] tracking-normal text-left  mr-2";

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customer: "",
      organization: "",
      prefix: "",
      delayed_job: false,
      assigned_to_team: "",
      assigned_to: [],
      approved_by: [],
      amount_based_declared: false,
      job_value: {
        Amount: "",
        CurrencyCode: "",
      },
      estimated_value: "",
      balance_amount: "",
      job_value_history: [],

      parent_id: "",
      order_id: "",
      title: "",
      description: "",
      category: "",
      priority: "",
      type: "",
      tags: "",
      feedback: [],
      scheduled_start_time: "",
      start_time: "",
      scheduled_end_time: "",
      end_time: "",
      due_date: "",
      job_auto_checkout: false,
      finance_incomeexpense: false,
      scheduled_duration: "",
      actual_start_time: "",
      actual_end_time: "",
      actual_duration: 0,
      work_mins_required: 0,
      current_status: {},
      status: {},
      lat: "",
      long: "",
      customer_address: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        street: "",
        city: "",
        landmark: "",
        state: "",
        country: "",
        zip_code: "",
        geo_cordinates: [],
      },
      customer_billing_address: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        street: "",
        city: "",
        landmark: "",
        state: "",
        country: "",
        zip_code: "",
        geo_cordinates: [],
      },
      custom_fields: [],
      products: [],
      workOrder: [],
      recurring_job: "",
      Job_Routes: "",
      request: "",
      is_recurrence: false,
      invoice: {},
      attachments: [],
      details_url: "",
      feedback_url: "",
      child_jobs: [],
    },
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
      }, 400);

      const updatedAssignedTeam = [];

      formik.values.assigned_to?.forEach((each) => {
        if (!updatedAssignedTeam.includes(each.team_id)) {
          updatedAssignedTeam.push(each.team_id);
        }
      });
      delete values["_id"];
      delete values["__v"];

      if (values.organization === "") {
        delete values["organization"];
      }
      if (values.customer === "") {
        delete values["customer"];
      }

      if (values.recurring_job === "") {
        delete values["recurring_job"];
      }
      if (values.Job_Routes === "") {
        delete values["Job_Routes"];
      }
      if (values.request === "") {
        delete values["request"];
      }
    },
  });

  const renderCurrentTab = () => {
    switch (currentTab) {
      case tabConstant.customerDetails:
        return <CustomersDetails/>;
      case tabConstant.jobDetails:
        return <JobDetails />;
      case tabConstant.assignDetails:
        return  <JobAssignUsers/>;
      case tabConstant.previewAndSave:
        return <PreviewAndSave/>;
      default:
        return null;
    }
  };

  const onClickJobDetails = () => {
    setCurrentTab(tabConstant.jobDetails);
  };

  const onClickAssignJob = () => {
    setCurrentTab(tabConstant.assignDetails);
  };

  const onClickPreviewAndSave = () => {
    setCurrentTab(tabConstant.previewAndSave);
  };

  const handleNext = () => {
    if (currentTab === tabConstant.customerDetails) {
      setCurrentTab(tabConstant.jobDetails);
    } else if (currentTab === tabConstant.jobDetails) {
      setCurrentTab(tabConstant.assignDetails);
    } else {
      setCurrentTab(tabConstant.previewAndSave);
    }
    if (currentTab === tabConstant.assignDetails) {
      setCurrentTab(tabConstant.previewAndSave);
    }
  };

  const jobDetailsCondition =
    (formik.touched?.title &&
      formik.errors?.title &&
      formik.values?.title === "") ||
    (formik.touched?.category &&
      formik.errors?.category &&
      formik.values?.category === "") ||
    (formik.touched?.priority &&
      formik.errors?.priority &&
      formik.values?.priority === "") ||
    (formik.touched?.type &&
      formik.errors?.type &&
      formik.values?.type === "") ||
    formik.values?.custom_fields?.filter(
      (each) =>
        each.is_error &&
        formik.touched?.category &&
        formik.values[each.ref_uid] === ""
    )[0];

  const assignDetailsCondition =
    formik.touched?.assigned_to_team &&
    formik.errors.assigned_to_team &&
    formik.values.assigned_to_team === "";

  const handleOnSave = () => {
    // firstEle = true;
    if (
      formik.errors.title ||
      formik.errors.category ||
      formik.values.title === "" ||
      formik.values.category === "" ||
      formik.errors.priority ||
      formik.values.priority === "" ||
      formik.errors.type ||
      formik.values.type === "" ||
      formik.values?.custom_fields?.filter(
        (each) => each.is_error && formik.values[each.ref_uid] === ""
      )[0]
    ) {
      setCurrentTab(tabConstant.jobDetails);
      formik.handleSubmit();
      // formikFinance.handleSubmit();
      return;
    }

    if (
      formik.errors.assigned_to_team ||
      formik.values.assigned_to_team === ""
    ) {
      setCurrentTab(tabConstant.assignDetails);
      formik.handleSubmit();

      return;
    }
    formik.handleSubmit();
  };

  useEffect(() => {
    if (
      currentTab === tabConstant.assignDetails ||
      currentTab === tabConstant.previewAndSave
    ) {
      formik.setFieldTouched("title", true);
      formik.setFieldTouched("category", true);
      formik.setFieldTouched("priority", true);
      formik.setFieldTouched("type", true);
      formik.setFieldTouched("title", true);
    }
  }, [currentTab]);

  return (
    <>
      <div className="main-container">
        <SubHeader
          headings={[
            {
              name: "Job",
              url: "/job",
            },
            {
              name: "New Job",
            },
          ]}
        />

        <div className="flex justify-center w-full">
          <div className="flex justify-center items-center rounded border border-gray-300 bg-white p-3 mt-6 mb-5.5">
            <div className="flex items-center">
              {currentTab === tabConstant.customerDetails ? (
                <div
                  onClick={() => setCurrentTab(tabConstant.customerDetails)}
                  className={`${
                    currentTab === tabConstant.customerDetails
                      ? activeCon
                      : "w-7 h-7 rounded-full bg-gray-300 mr-1 cursor-pointer"
                  }`}
                ></div>
              ) : (
                <div
                  onClick={() => setCurrentTab(tabConstant.customerDetails)}
                  className={activeCon}
                >
                  <DoneIcon
                    className="text-white"
                    style={{ fontSize: "20px" }}
                  />
                </div>
              )}
              <p
                className={`${
                  currentTab === tabConstant.customerDetails
                    ? activePara
                    : "text-[10px] font-semibold text-left text-dark-green mr-2"
                }`}
              >
                {"CUSTOMER DETAILS"}
              </p>
              <hr className="w-32 border-[#D1DDDB]" />
            </div>
            <div className="flex items-center">
              {currentTab !== tabConstant.assignDetails &&
              currentTab !== tabConstant.previewAndSave ? (
                <div
                  onClick={() => {
                    onClickJobDetails();
                  }}
                  className={`${
                    currentTab === tabConstant.jobDetails &&
                    !jobDetailsCondition
                      ? activeCon
                      : jobDetailsCondition
                      ? errorCon
                      : "w-7 h-7 rounded-full bg-gray-300 mr-1 cursor-pointer"
                  }`}
                ></div>
              ) : (
                <div
                  onClick={() => setCurrentTab(tabConstant.jobDetails)}
                  className={`${jobDetailsCondition ? errorCon : activeCon}`}
                >
                  {jobDetailsCondition ? (
                    <CloseIcon
                      className="text-white"
                      style={{ fontSize: "20px" }}
                    />
                  ) : (
                    <DoneIcon
                      className="text-white"
                      style={{ fontSize: "20px" }}
                    />
                  )}
                </div>
              )}
              <p
                className={`${
                  currentTab === tabConstant.jobDetails && !jobDetailsCondition
                    ? activePara
                    : jobDetailsCondition
                    ? errorPara
                    : "text-[10px]  font-semibold text-left text-dark-green mr-2"
                }`}
              >
                {"JOB DETAILS"}
              </p>
              <hr className="w-32 border-[#D1DDDB]" />
            </div>
            <div>
              <div className="flex items-center">
                {currentTab !== tabConstant.previewAndSave ? (
                  <div
                    onClick={() => {
                      onClickAssignJob();
                    }}
                    className={`${
                      currentTab === tabConstant.assignDetails &&
                      !assignDetailsCondition
                        ? activeCon
                        : assignDetailsCondition
                        ? errorCon
                        : "w-7 h-7 rounded-full bg-gray-300 mr-1 cursor-pointer"
                    }`}
                  ></div>
                ) : (
                  <div
                    onClick={() => setCurrentTab(tabConstant.assignDetails)}
                    className={`${
                      assignDetailsCondition ? errorCon : activeCon
                    }`}
                  >
                    {assignDetailsCondition ? (
                      <CloseIcon
                        className="text-white"
                        style={{ fontSize: "20px", color: "white" }}
                      />
                    ) : (
                      <DoneIcon
                        className="text-white"
                        style={{ fontSize: "20px" }}
                      />
                    )}
                  </div>
                )}
                <p
                  className={`${
                    currentTab === tabConstant.assignDetails &&
                    !assignDetailsCondition
                      ? activePara
                      : assignDetailsCondition
                      ? errorPara
                      : "text-[10px]  font-semibold text-left text-dark-green mr-2"
                  }`}
                >
                  {"ASSIGN USERS"}
                </p>
                <hr className="w-32 border-[#D1DDDB]" />
              </div>
            </div>
            <div className="flex items-center">
              <div
                onClick={() => {
                  onClickPreviewAndSave();
                }}
                className={`${
                  currentTab === tabConstant.previewAndSave
                    ? activeCon
                    : "w-7 h-7 rounded-full bg-gray-300 mr-1 cursor-pointer"
                }`}
              ></div>
              <p
                className={`${
                  currentTab === tabConstant.previewAndSave
                    ? activePara
                    : "text-[10px] font-semibold text-left text-dark-green mr-2"
                }`}
              >
                {"PREVIEW & SAVE"}
              </p>
            </div>
          </div>
        </div>
        {renderCurrentTab()}
      </div>
    </>
  );
};

export default JobForm;
