import React, { useState } from "react";
import { Add } from "@mui/icons-material";
import SubHeader from "../../Components/Molecules/Subheader";
import CustomTable from "../../Components/Molecules/CustomTable";
import FilterSortby from "../../Components/Molecules/FilterSortBy";
import { useNavigate } from "react-router-dom";

const PurchasePage = () => {
  const navigate = useNavigate();
  const data = [
    {
      quoteID: "PU-5901",
      questionGroup: "New Purchase Order",
      noOfQuestions: "Falken",
      notifyEndDate: "0",

      answeredStatus: "02/02/2022",
      reviewedStatus: "pavi",
      reviewedStatuss: "pavi",

      reviewedStatusss: "pavi",
    },
  ];
  const [page, setPage] = useState(1);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");

  const sortList = [
    { name: "Created Date", value: "createdAt-ASC" },
    { name: "Created Date", value: "createdAt-DSC" },
    { name: "Name-ASC", value: "name-ASC" },
    { name: "Name-DSC", value: "name-DSC" },
  ];

  return (
    <>
      <div className="">
        <SubHeader
          headings={[
            {
              name: "Purchase Order",
            },
          ]}
          actions={[
            {
              type: "button",
              name: "New PurchaseOrder",

              onclick: () => {
                navigate("/purchase/form");
              },
              icon: (
                <Add
                  sx={{
                    fontSize: "18px",
                    marginRight: "5px",
                    color: "#7F7F7F",
                  }}
                />
              ),
            },
          ]}
        />
        <div>
          {" "}
          <FilterSortby
            exportBtn="false"
            totalPage={"3"}
            getPageNumber={setPage}
            getQuickFilter={setQuickFilter}
            getSortByValue={setSortBy}
            sortList={sortList}
            roleList={[{ name: "All Roles", _id: "All Roles", disabled: true }]}
            onClickFilter={() => {}}
          />
          <div className="overflow-auto">
            <CustomTable
              tableTitle={[
                "Order no #",
                "order title",
                "order type",
                "no of products",
                "expected delivery date",
                "Deliver to",
                "Address",
                "created at",
                "actions",
              ]}
              navigateView={() => navigate("/purchaseorderview")}
              tableData={data}
              viewButton={true}
              editButton={true}
              deleteButton={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchasePage;
