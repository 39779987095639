import React from "react";
import CustomInput from "../../../Components/Atoms/CustomInput";
import CustomCancelSubmit from "../../../Components/Atoms/CustomCancelSubmit";
import FileInput from "../../../Components/Atoms/FileInput";

const JobAttachment = () => {
  return (
    <>
      <div className="">
        <div className="mb-8">
          <CustomInput
            margin="true"
            labelText="Shift Start Date"
            name="username"
            placeholder="Enter first name"
            type="text"
          />
          <FileInput
            label={"product_file"}
            id="part-manual"
            text={"No Files Selected"}
          />
        </div>

        <CustomCancelSubmit cancelLabel="Close" submitLabel="Save" />
      </div>
    </>
  );
};

export default JobAttachment;
