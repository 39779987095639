import React, { useRef, useState } from "react";
import TextArea from "../../Components/Atoms/TextArea";
import CustomCancelSubmit from "../../Components/Atoms/CustomCancelSubmit";
 
 
 

const WorkInstructionForm = () => {
   

  
  return (
    <>
       <TextArea
                name="description"
                
                required="true"
                placeholder={"Add notes"}
                type="text"
      />
        <CustomCancelSubmit cancelLabel="Close" submitLabel="Save" />
      
    </>
  );
};

export default WorkInstructionForm;
