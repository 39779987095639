import React, { useRef, useState } from "react"; 
import ComponentTitle from "../../Components/Atoms/ComponentTitle";
import CustomInput from "../../Components/Atoms/CustomInput";
import TextArea from "../../Components/Atoms/TextArea";
import { TableIconConstant } from "../../constants";
import CustomButton from "../../Components/Atoms/CustomButton"; 
import CustomSelect from "../../Components/Atoms/CustomSelect"; 
import Whitespace from "../../Components/Atoms/WhiteSpace"; 
import SubHeader from "../../Components/Molecules/Subheader";
 

const ProcessLossForm = (props) => {
  const handleFilesChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const obj = {
        file_name: e.target.files[i].name,
        url: "",
        created_by: props.userId,
        attachment: e.target.files[i],
      };
    }
  };
  const hiddenFileInput = useRef(null);
  const handleUploadBtn = () => {
    hiddenFileInput?.current?.click();
  };
  return (
      <>
          
           <SubHeader
          headings={[
            {
              name: "Production",
                      },
                {
              name: "Project no. 5901",
                  },
                 {
              name: " New Process Loss",
            },
          ]}
          actions={[
 
             {
              type: "button",
              btnType: "submit",
              name: "Save",
            },
             
          ]}
              />
      <Whitespace mt="23px" className={"px-7 py-7 w-full"}>
              <ComponentTitle title={"process loss settings"} />
              <div class="grid grid-cols-[1fr_3fr_2fr] gap-4">
                  <div>
                       <CustomInput
            margin="true"
            labelText="Cost Element Prefix"
            name="username"
            placeholder="AB-001"
            type="text"
          />
      </div>
      <div>
     <CustomInput
            margin="true"
            labelText="Cost Element"
            name="username"
            placeholder="Enter Cost Element"
            type="text"
          />
        </div>
        <div>
            <CustomSelect
            margin="true"
            labelText="Target Cost"
            name="username"
            placeholder="Enter Target Cost"
            type="text"
          />
    </div>
</div>
       
        <div className="columnThree">
          <CustomInput
            margin="true"
            labelText="Actual Cost"
            name="username"
            placeholder="Enter Actual Cost"
            type="text"
                  />
                    <CustomInput
            margin="true"
            labelText="Material Loss"
            name="username"
            placeholder="Enter Material Loss"
            type="text"
                  />  <CustomInput
            margin="true"
            labelText="Time Loss"
            name="username"
            placeholder="Enter Time Loss"
            type="text"
                  />
                
              </div>
              <div className="columnThree">
          <CustomInput
            margin="true"
            labelText="Quality Loss"
            name="username"
            placeholder="Enter Quality Loss"
            type="text"
                  />
                    <CustomInput
            margin="true"
            labelText="Energy Loss"
            name="username"
            placeholder="Enter Energy Loss"
            type="text"
                  />   
                
              </div>
            
        <TextArea labelText={"Description"} placeholder={"Enter Description"} />
          </Whitespace>
          
           
      <Whitespace className={"mt-5"}>
        <div className="flex items-center justify-between mb-10">
          <ComponentTitle title={"PARTS & SERVICES"}></ComponentTitle>
          <div className="flex gap-3">
            <CustomButton size="medium" buttonType={TableIconConstant.add}>
              Add&nbsp;Item
                      </CustomButton>
                       <CustomButton size="medium" buttonType={TableIconConstant.add}>
            Add&nbsp;Item&nbsp;From&nbsp;Group
            </CustomButton>
          </div>
        </div>
        <input
          onChange={handleFilesChange}
          ref={hiddenFileInput}
          style={{ display: "none" }}
          type="file"
          multiple
        />
        {/* {formik.values.attachments.length === 0 ? ( */}
        <div className="flex flex-col justify-center items-center py-9">
          <h1 className="text-[17px] font-semibold leading-[19px] text-black mb-[16px]">
            {"Add Parts & Services"}
          </h1>
          <p className="text-[14px] font-normal leading-[15px] text-[#9d9d9d] mb-[25px]">
            {
              "Add any parts or services of any custom line item to this Job"
            }
          </p>

          <CustomButton
            size="medium"
            onClick={handleUploadBtn}
            buttonType={TableIconConstant.add}
          >
            Add&nbsp;Item
          </CustomButton>
        </div>
          </Whitespace>
           
    </>
  );
};

export default ProcessLossForm;
