import React from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";
import CustomButton from "../../../Components/Atoms/CustomButton";

export default function SalesOrderMessageView({ type }) {
  return (
    <div className="mt-5 flex justify-betweens">
      <div className="">
        <ComponentTitle title={`Lead Assinged To`} mb="0" />
        <div className="mt-2 h-[62vh] overflow-auto no-scrollbar">
          <div className="px-5 flex items-center gap-1.5 cursor-pointer">
            <p className="h-9 w-9 m-0 rounded-full p-2.5 flex items-center justify-center bg-[#D5D5D5]">
              {"K"}
            </p>
            <div className="ml-2">
              <p className="text-sm font-medium text-[#140C00]">{"pavithra"}</p>
              <p className="text-xs text-gray-600">{"pavi@gmail.com"}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full border-l border-gray-200 pl-1.5">
        <div className="p-2.5 bg-[#FFEBD8] flex gap-2 items-center rounded">
          <div className="h-7 w-7 text-[12px] rounded-full p-1.5 flex items-center justify-center bg-[#E0D2C1]">
            {"K"}
          </div>
          <p className="text-sm">{"Pavithraaaa"}</p>
        </div>
        <div className="overflow-auto h-[58vh] no-scrollbar">
          <div className="w-full flex justify-start mt-2">
            <div className="ml-2 flex flex-col max-w-[60%]">
              <p className="px-5 py-2.5 bg-[#FFEBD8] rounded-r-md rounded-bl-md text-[#002e25b2] text-xs mt-1.5  relative">
                Once upon a time, there..
                <span className="text-[9px] font-light block mt-1">
                  23/324/23
                </span>
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 pr-5 mt-2">
            <div className="col-span-2 flex justify-end">
              <div className="mr-2 flex flex-col items-end relative">
                <p className="px-5 py-2.5 bg-[#efefef] rounded-l-md rounded-br-md text-[#002e25b2] text-xs mt-1.5 relative">
                  Once upon a time, there was a Greek King, Midas...
                  <span className="text-[9px] font-light block mt-1 text-right">
                    23/324/23
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex relative border border-gray-200 rounded-md mt-2">
          <input
            className="flex-grow border-none focus:outline-none rounded-l-md p-3 text-[#140C00]"
            type="text"
            placeholder="Type Your Message"
          />
          <input type="file" style={{ display: "none" }} />
          <div className="flex items-center gap-3">
            <AttachFileIcon
              className="cursor-pointer"
              sx={{ fontSize: "25px", color: "#140C00", opacity: "60%" }}
            />

            <CustomButton btnType="primary" size="large">
              send
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
}
