import React, { useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";
import TableButton from "../../../Components/Atoms/TableButton";
import Whitespace from "../../../Components/Atoms/WhiteSpace";
import { TableIconConstant } from "../../../constants";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CustomButton from "../../../Components/Atoms/CustomButton";
import { Add } from "@mui/icons-material";
 
import SubHeader from "../../../Components/Molecules/Subheader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
 
import Done from "@mui/icons-material/Done";

import JobAttachment from "./jobAttachment";
import JobSchedule from "./jobScheduled";
import JobActivites from "./jobActivity";
import JobNotes from "./jobNotes";
import JobDetails from "./JobDetails";
import JobMessageView from "./jobMessageView";
import JobAssignment from "./JobAssignment";

const JobviewPage = () => {
  const [currentmenu, setCurrentmenu] = useState("Current Jobs");
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [scheduleOpen, setcSheduleOpen] = useState(false);

  const handleScheduleOpen = () => {
    setcSheduleOpen(true);
  };
  const handleScheduleClose = () => {
    setcSheduleOpen(false);
  };

  const [leadApprovalOpen, setLeadApprovalOpen] = useState(false);

  const handleLeadApprovalOpen = () => setLeadApprovalOpen(true);
  const handleLeadApprovalClose = () => {
    setLeadApprovalOpen(false);
  };
  return (
    <>
      <CustomDialog
        width="60%"
        minWidth="150px"
        height="100%"
        maxHeight="100%"
        right="0px"
        position="relative"
        left="none"
        handleClose={handleLeadApprovalClose}
        open={leadApprovalOpen}
        Title={"Edit Job Assignment"}
        innerHeight="90vh"
      >
        <JobAssignment />
      </CustomDialog>
      <CustomDialog
        width="600px"
        minWidth="750px"
        Title={"Upload Attachement"}
        open={open}
        handleClose={handleClose}
      >
        <JobAttachment />
      </CustomDialog>
      <CustomDialog
        width="600px"
        minWidth="750px"
        Title={"Schedule Follow-up"}
        open={scheduleOpen}
        handleClose={handleScheduleClose}
      >
        <JobSchedule />
      </CustomDialog>
      <SubHeader
              headings={[
          {
            name: "Job",
          },
          {
            name: "Job View",
          },
        ]}
        actions={[
          {
            name: "More Action",
            icon: (
              <KeyboardArrowDownIcon
                sx={{ fontSize: "16px", marginLeft: "8px" }}
              />
            ),
            iconPosition: "right",
            type: "menu_drop_down",
            children: [
              {
                label: "edit_job_description",
              },
              {
                label: "edit_custom_field",
              },
              {
                label: "delete_job",
              },
            ],
          },
        ]}
      />
      <div className="flex gap-10 mt-5">
        <div className="max-h-[73vh] overflow-y-auto pl-6">
          <div className="flex flex-col grow gap-5 rounded-sm border-l border-[#d9d9d9] pl-6 pb-8 relative">
            <div className="absolute w-[11px] h-[11px] rounded-full bg-[#d9d9d9] left-[-6px] bottom-0"></div>

            <div className="flex justify-between items-center p-1.5 bg-white border relative">
              <div className="absolute top-1 left-[-11px] border-t-transparent border-r-black border-b-transparent border-t-8 border-r-12 border-b-8 w-0 h-0">
                <div className="h-6 w-6 rounded-full bg-primColor flex justify-center items-center absolute -left-[25px] -top-3">
                  <Done sx={{ color: "white", fontSize: "18px" }} />
                </div>
              </div>
              <div className="items-center p-2 min-w-[290px]">
                <p className="text-ellipsis text-sm overflow-hidden font-semibold mb-1">
                  New Request
                </p>
                <div className="flex items-center gap-1">
                  <span className="text-xs">
                    <AccessTimeIcon sx={{ fontSize: "17px" }} />
                  </span>
                  <p className="text-ellipsis overflow-hidden text-[14px] font-medium">
                    05/05/2022
                  </p>
                </div>
                <div className="flex items-center mt-0.5 gap-1">
                  <span className="text-xs">
                    <AccountCircleIcon sx={{ fontSize: "17px" }} />
                  </span>
                  <p className="text-ellipsis overflow-hidden text-[14px] font-medium">
                    Pavithra
                  </p>
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center p-1.5 bg-white border relative">
              <div className="absolute top-1 left-[-11px] border-t-transparent border-r-black border-b-transparent border-t-8 border-r-12 border-b-8 w-0 h-0">
                <div className="absolute left-0 top-[-8px] border-t-transparent border-r-white border-b-transparent border-t-8 border-r-12 border-b-8 w-4 h-4"></div>
              </div>
              <div className="p-2">
                <div className="absolute h-6 w-6 rounded-full left-[-39px] top-[-3px] bg-white border-[5px] border-[#d9d9d9]"></div>
                <div>
                  <p className="text-ellipsis text-sm overflow-hidden font-semibold mb-1">
                    Update Job Status
                  </p>
                  <div className="p-1 w-full rounded border mt-2 border-[#d2d2d2]">
                    <select className="w-full max-w-[200px] filter-dropdown bg-none">
                      <option >Choose Job Status</option>
                      <option>name</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white p-2.5 outline-none flex-grow border border-gray-300 rounded h-fit">
          <ul className="flex justify-between bg-gray-200 p-0.5 list-none border border-gray-300 my-0">
            <li
              className={`w-1/3 flex justify-center items-center cursor-pointer text-xs font-semibold leading-[15px] text-[#002e25] m-0 p-2.5 transition-all duration-200 ${
                currentmenu === "Current Jobs"
                  ? "bg-white rounded shadow-sm"
                  : ""
              }`}
              onClick={() => setCurrentmenu("Current Jobs")}
            >
              Job Details
            </li>
            <li
              className={`w-1/3 flex justify-center items-center cursor-pointer text-xs font-semibold leading-[15px] text-[#002e25] m-0 p-2.5 transition-all duration-200 ${
                currentmenu === "Work Hours" ? "bg-white rounded shadow-sm" : ""
              }`}
              onClick={() => setCurrentmenu("Work Hours")}
            >
              Notes
            </li>
            <li
              className={`w-1/3 flex justify-center items-center cursor-pointer text-xs font-semibold leading-[15px] text-[#002e25] m-0 p-2.5 transition-all duration-200 ${
                currentmenu === "Activity" ? "bg-white rounded shadow-sm" : ""
              }`}
              onClick={() => setCurrentmenu("Activity")}
            >
              Activity
            </li>
            <li
              className={`w-1/3 flex justify-center items-center cursor-pointer text-xs font-semibold leading-[15px] text-[#002e25] m-0 p-2.5 transition-all duration-200 ${
                currentmenu === "Document" ? "bg-white rounded shadow-sm" : ""
              }`}
              onClick={() => setCurrentmenu("Document")}
            >
              Messages
            </li>
          </ul>
          {currentmenu === "Current Jobs" ? (
            <>
              <JobDetails />
            </>
          ) : null}
          {currentmenu === "Work Hours" ? (
            <>
              <JobNotes />
            </>
          ) : null}

          {currentmenu === "Activity" ? (
            <>
              <JobActivites />
            </>
          ) : null}

          {currentmenu === "Document" ? (
            <>
              <JobMessageView />
            </>
          ) : null}
        </div>
        <div>
          <Whitespace mb="10px">
            <div className="flex justify-between">
              <ComponentTitle title="Follow-up scheduled at" />
              <TableButton
                size="small"
                buttonType={TableIconConstant.edit}
                onClickBtn={handleScheduleOpen}
              />
            </div>
            <div className="pr-20">
              <p className="font-medium text-[14px]">
                2024/01/16 01:30 to 2024/01/16 03:30
              </p>
            </div>
          </Whitespace>
          <Whitespace mb="10px">
            <div className="flex justify-between">
              <ComponentTitle title="USER TEAMS ASSIGNED" />
              <TableButton
                size="small"
                buttonType={TableIconConstant.edit}
                onClickBtn={handleLeadApprovalOpen}
              />
            </div>
            <div className="pr-20">
              <div className="flex mb-5">
                <div className="w-10 text-[17px]  h-10 font-bold rounded-full bg-gray-300 flex justify-center items-center mr-2.5">
                  U
                </div>
                <div className="flex flex-col justify-evenly">
                  <p className="text-xs font-semibold leading-3 text-left  m-0">
                    pavi
                  </p>
                  <p className="text-xs font-medium leading-6 opacity-80 text-left m-0">
                    Skills: Skill one, Skill two
                  </p>
                </div>
              </div>
              <div className="flex mb-5">
                <div className="w-10 text-[17px]  h-10 font-bold rounded-full bg-gray-300 flex justify-center items-center mr-2.5">
                  U
                </div>
                <div className="flex flex-col justify-evenly">
                  <p className="text-xs font-semibold leading-3 text-left  m-0">
                    pavi
                  </p>
                  <p className="text-xs font-medium leading-6 text-left opacity-80 m-0">
                    Skills: Skill one, Skill two
                  </p>
                </div>
              </div>
            </div>
          </Whitespace>
          <Whitespace pd={""} mb="10px">
            <div className="flex justify-between  ">
              <ComponentTitle title="ORGANIZATION" />
              <TableButton size="small" buttonType={TableIconConstant.edit} />
            </div>
            <div className="flex gap-4 ">
              <AccountCircleIcon
                className="bg-white text-gray-300 mr-2.5 w-14 h-14 rounded-full overflow-hidden"
                sx={{ fontSize: "60px" }}
              />

              <div className="">
                <p className="font-medium">{"Sample Organization"}</p>
                <div className="flex items-center gap-3">
                  <MailIcon
                    className="job-profile-icon"
                    sx={{ fontSize: "14px" }}
                  />
                  <p className="text-[14px] font-medium leading-6 text-left opacity-80 m-0">
                    {" "}
                    {"email"}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <LocationOnIcon
                    className="job-profile-icon"
                    sx={{ fontSize: "14px" }}
                  />
                  <div>
                    <p className="text-[14px] font-medium leading-6 text-left opacity-80 m-0">
                      {" "}
                      address
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Whitespace>
          <Whitespace>
            <ComponentTitle title="attachments" />
            <div className="flex flex-col gap-2 items-center mb-8">
              <p className="text-[14px] font-medium leading-6 text-left opacity-80 m-0">
                No Data Found
              </p>
              <CustomButton size="medium" onClick={handleOpen}>
                <Add sx={{ color: "#696969", fontSize: "17px" }} />
                Add Attachments
              </CustomButton>
            </div>
          </Whitespace>
        </div>
      </div>
    </>
  );
};

export default JobviewPage;
