import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SendIcon from "@mui/icons-material/Send";
import React, { useRef } from "react";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TableButton from "../../Components/Atoms/TableButton";
import SubHeader from "../../Components/Molecules/Subheader";
import Whitespace from "../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../Components/Atoms/ComponentTitle";
import { TableIconConstant } from "../../constants";
import { useState } from "react";
import CustomButton from "../../Components/Atoms/CustomButton";
import { Add, Done } from "@mui/icons-material";
import ViewQuotationCustomerDetails from "./viewQuotaionCustomerDetails";
 

const tabConstant = {
  draft: "DRAFT",
  sent: "AWAIT_PAYMENT",
  paid: "PAID",
  partially_paid: "PARTIALLY_PAID",
  archived: "ARCHIVED",
  cancelled: "CANCELED",
};

const QuotaionView = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const quotation_status = "draft"
  
  const status_history = [
    {
      status_name: "CANCELED"
    },
      
  ]
  return (
    <>
      <>
        <div className="">
          <SubHeader
            headings={[
              {
                name: "Quotation",
                url: "/quotatiom",
              },
              {
                name: `Quotation View`,
              },
            ]}
            actions={[
              {
                type: "button",
                btnType: "secondary",
                name: "print",
                onclick: () => {
                  window.print();
                },
                icon: (
                  <PrintIcon
                    sx={{
                      fontSize: "16px",
                      marginRight: "8px",
                    }}
                  />
                ),
              },
              {
                type: "button",
                name: "pdf",

                icon: (
                  <PictureAsPdfIcon
                    sx={{
                      fontSize: "16px",
                      marginRight: "8px",
                    }}
                  />
                ),
              },
              {
                type: "button",
                name: "send",

                icon: (
                  <SendIcon
                    sx={{
                      fontSize: "16px",
                      marginRight: "8px",
                    }}
                  />
                ),
              },
              {
                name: "more",
                icon: (
                  <KeyboardArrowDownIcon
                    sx={{ fontSize: "16px", marginLeft: "8px" }}
                  />
                ),
                iconPosition: "right",
                type: "menu_drop_down",
                children: [
                  {
                    name: "edit_invoice",
                  },
                  {
                    name: "cancel_invoice",
                  },
                  {
                    name: "mark_as_paid",
                  },

                  {
                    name: "archive_invoice",
                  },
                  {
                    name: "delete_invoice",
                  },
                ],
              },
            ]}
          />
          <div className="flex gap-4">
            <div className="w-[75%]">
              <div className="flex justify-center">
  <div className="relative  border-[#d1dddb] mt-3">
    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-full border-r border-[#d1dddb] border-t-[0.1px] z-10 mt-3"></div>
    <div className="flex items-center  relative z-20">
      <div className="w-6 h-6 rounded-full border-[5px] border-gray-300 bg-white relative z-30 ">
       
      </div>
    </div>
    <p className="relative z-2  w-24 -ml-2 mt-1 font-medium">Draft</p>
  </div>
  <div className="relative  border-[#d1dddb] mt-3">
    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-full border-r border-[#d1dddb] border-t-[0.1px] z-10 mt-3"></div>
    <div className="flex items-center  relative z-20">
      <div className="w-6 h-6 rounded-full border-[5px] border-gray-300 bg-white relative z-30 ">
       
      </div>
    </div>
    <p className="relative z-2 w-24 -ml-2 mt-1 font-medium">Sent</p>
  </div>  <div className="relative  border-[#d1dddb] mt-3">
    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-full border-r border-[#d1dddb] border-t-[0.1px] z-10 mt-3"></div>
    <div className="flex items-center  relative z-20">
      <div className="w-6 h-6 rounded-full border-[5px] border-gray-300 bg-white relative z-30 ">
       
      </div>
    </div>
    <p className="relative z-2  w-24 -ml-2 mt-1 font-medium">Paid</p>
  </div>  <div className="relative  border-[#d1dddb] mt-3">
    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-full border-r border-[#d1dddb]  z-10 mt-3"></div>
    <div className="flex items-center  relative z-20">
      <div className="w-6 h-6 rounded-full border-[5px] border-gray-300 bg-white relative z-30 ">
       
      </div>
    </div>
    <p className="relative z-2 -ml-5 mt-1 font-medium">Archived</p>
                              </div>
                          </div>
              <ViewQuotationCustomerDetails tabConstant={tabConstant} />
            </div>
            

            
            <div className="w-[25%]">
              <div className="mb-4">
                <Whitespace pd={""} mb="10px">
                  <div className="flex justify-between items-center mb-2">
                    <ComponentTitle title="ORGANIZATION" mb="0" />
                  <p className="text-primColor whitespace-nowrap "> View Details</p>
                  </div>
                  <div className="flex gap-1">
                    <AccountCircleIcon
                      className="bg-white text-gray-300 mr-2.5 w-14 h-14 rounded-full overflow-hidden"
                      sx={{ fontSize: "60px" }}
                    />

                    <div className="">
                      <p className="font-medium text-[14px]">
                        {"Sample Organization"}
                      </p>
                      <div className="flex items-center gap-3">
                        <MailIcon
                          className=" "
                          sx={{ fontSize: "14px", color: "grey" }}
                        />
                        <p className="text-[14px] font-medium leading-6 text-left opacity-80 m-0">
                          {"Email"}
                        </p>
                      </div>
                      <div className="flex  gap-3">
                        <LocationOnIcon
                          className=""
                          sx={{ fontSize: "14px", color: "grey", marginTop:"5px" }}
                        />
                        <div>
                          <p className="text-[14px] font-medium leading-6 text-left opacity-80 m-0">
                            Sample Address,
                          </p>
                          <p className="text-[14px] font-medium leading-6 text-left opacity-80 -m-1 ml-[1px]">
                            Sample Area,
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Whitespace>
              </div>
              <div className="mb-4">
                <Whitespace pd={""} mb="10px">
                  <div className="flex justify-between items-center mb-2">
                    <ComponentTitle title="ORGANIZATION" mb="0" />
                    
                  </div>
                  <div className="flex gap-1">
                    <AccountCircleIcon
                      className="bg-white text-gray-300 mr-2.5 w-14 h-14 rounded-full overflow-hidden"
                      sx={{ fontSize: "60px" }}
                    />

                    <div className="">
                      <p className="font-medium text-[14px]">
                        {"Sample Organization"}
                      </p>
                      <div className="flex items-center gap-2">
                        <MailIcon
                          className=" "
                          sx={{ fontSize: "14px", color: "grey" }}
                        />
                        <p className="text-[14px] font-medium leading-6 text-left opacity-80 m-0">
                          {"Email"}
                        </p>
                      </div>
                      <div className="flex  gap-2">
                        <LocationOnIcon
                          className=""
                          sx={{ fontSize: "14px", color: "grey" }}
                        />
                        <div>
                          <p className="text-[14px] font-medium leading-6 text-left opacity-80 m-0">
                            Sample Address,
                          </p>
                                                    <p className="text-[14px] font-medium leading-6 text-left opacity-80 -m-1 ml-[1px]">
                            Sample Area,
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Whitespace>
              </div>
               <div className="mb-4">
                <Whitespace pd={""} mb="10px">
                  <div className="flex justify-between items-center mb-2">
                    <ComponentTitle title="PENDING AMOUNT" mb="0" />
                  <p className="text-[#9E2F00] whitespace-nowrap bg-[#FFE1D3] px-3 m-0 text-[10px]  rounded-[30px]">COLLECT DUE AMOUNT</p>
                  </div>
                  <p className="text-[#9E2F00] text-[35px] mt-5">INR 10387.90</p>
                  
                </Whitespace>
              </div>
                <div className="mb-4">
                <Whitespace pd={""} mb="10px">
                  <div className="flex justify-between items-center mb-2">
                    <ComponentTitle title="financing" mb="0" />
                  <p className="text-[#9E2F00] whitespace-nowrap bg-[#FFE1D3] px-3  text-[10px]  rounded-[30px]">INITATED</p>
                  </div>
                  <p className="border-b text-[#2E0E00] bg-opacity-75 text-[14px] mb-2 pb-2">Application Sent to customer at 2024/01/29 06:49</p>
                    <ComponentTitle title="Requested By" mb="0" className="mt-2" />

                  <p className="border-b text-[#2E0E00] bg-opacity-75 text-[14px] mb-2 pb-2 mt-2">User Two at 2024/01/29 06:49</p>
                   <ComponentTitle title="REQUESTED AMOUNT" mb="0" className="mt-2" />

                  <p className="border-b text-[#2E0E00] bg-opacity-75 text-[14px] mb-2 pb-2 mt-2">INR 1000.00</p>

                  
                </Whitespace>
              </div>
              <div className="mb-4">
                <Whitespace>
                  <ComponentTitle title="attachments" />
                  <div className="flex flex-col gap-2 items-center mb-8">
                    <p className="text-[14px] font-medium leading-6 text-left opacity-80 m-0">
                      No Data Found
                    </p>
                    <CustomButton size="medium" onClick={handleOpen}>
                      <Add sx={{ color: "#696969", fontSize: "17px" }} />
                      Add Attachments
                    </CustomButton>
                  </div>
                </Whitespace>
              </div>
              <div className="mb-4">
                <Whitespace>
                  <ComponentTitle title="notes" />
                  <div className="flex flex-col gap-2 items-center mb-8">
                    <p className="text-[14px] font-medium leading-6 text-left opacity-80 m-0">
                      No Notes Found
                    </p>
                    <CustomButton size="medium" onClick={handleOpen}>
                      <Add sx={{ color: "#696969", fontSize: "17px" }} />
                      Add Notes
                    </CustomButton>
                  </div>
                </Whitespace>
              </div>
            </div>
          </div>
        </div>
      </>
  </>
  );
};

export default QuotaionView;
