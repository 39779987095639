import React from "react";
import CustomSelect from "../../Components/Atoms/CustomSelect"


const JobsFilter = () => {
  return (
    <>
      <div className="-ml-2 mb-[110px]">
        <CustomSelect
          labelText={"FILTER BY JOB CATEGORY"}
          placeholder={"Choose Category"}
         
        />
         <CustomSelect
          labelText={"FILTER BY SCHEDULED DATE & TIME"}
          placeholder={"Choose Dates"}
          
        />
         <CustomSelect
          labelText={"FILTER BY DUE DATE"}
          placeholder={"Choose Dates"}
          
        />
            <CustomSelect
          labelText={"FILTER BY STATUS"}
          placeholder={"Pick an option"}
          
        />
      </div>
    </>
  );
};

export default JobsFilter;
