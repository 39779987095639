 import  React from "react"
const ViewCustomerDetails = ({ tabConstant }) => {
    const invoice_status="CANCELED"


  return (
    <div   className="bg-white border border-gray-300 rounded-lg p-10 relative mt-5">
      <div
        className={`absolute left-0 top-1.5 px-3 py-1 rounded-r-full text-white ${
          invoice_status === tabConstant.cancelled
            ? "bg-primColor"
            :  invoice_status ===
              tabConstant.partially_paid
            ? "bg-orange-600"
            : "bg-teal-600"
        }`}
      >
        <p className="text-sm  text-white font-semibold">
          {invoice_status ===
          tabConstant.partially_paid
            ? "PARTIALLY PAID"
            :  invoice_status === tabConstant.sent
            ? "SENT"
            :  "PAID"}
        </p>
      </div>

      <div className="flex gap-4 mt-6 justify-between">
        <div className="flex items-center">
         
              {/* <img
                // src={`${BASE_URL}/${getInvoiceByIdResponse?.data?.template?.organizationLogo}`}
                alt="Organization Logo"
                className="w-24 h-24 object-cover"
              /> */}
                  <p className="bg-[#F6F6F6] py-11 rounded px-7 text-[#9DA0A0] text-[18px]">LOGO</p>
          
        </div>
              <div className="text-right">
                  
          <p className="text-[14px] font-[400] text-[#000000]">
            {'Invoice # :  123'}
            
          </p>
          <p className="text-[14px] font-[400] text-[#000000]">
            {'Invoice Date : 2023 / 23 / 23'}
           
          </p>
          <p className="text-[14px] font-[400] text-[#000000]">
                      {'Due Date : 2023 / 23 / 23'}
        
          </p>
          <p className="text-[14px] font-[400] text-[#000000]">
                       {'Created By : 2023 / 23 / 23'}
           
          </p>
        </div>
      </div>

      <div className="flex gap-4 mt-6 border border-gray-300 bg-[#F6F6F6] p-4 rounded-lg">
        <div className="w-1/2">
          <h1 className="text-sm font-semibold">
            Billing Address
          </h1>
          <p className="text-base font-medium mt-2">
            Pavithra 
          </p>
          <p className="text-sm">Sample street</p>
          <p className="text-sm mt-1">         
            
               Sample City, Sample State         
             
          </p>
          <p className="text-sm mt-1">
            
              
                Sample Country -  
              
         
            627416
          </p>
          <p className="text-sm mt-1">
            Email: usertwo@gway.com
           
          </p>
        </div>
        <div className="w-1/2">
          <h1 className="text-sm font-semibold">
            Service Address
          </h1>
            <p className="text-base font-medium mt-2">
            Pavithra 
          </p>
          <p className="text-sm">Sample street</p>
          <p className="text-sm mt-1">
            
            
               Sample City, Sample State
             
             
          </p>
          <p className="text-sm mt-1">
            
              <>
                Sample Country -  
              </>
         
            627416
          </p>
          <p className="text-sm mt-1">
            Email: usertwo@gway.com
           
          </p>
        </div>
      </div>

      <div className="mt-6">
        <table className="w-full border border-gray-300">
          <thead>
            <tr className="border-b p-1">
              <th className="p-2 text-left text-sm font-semibold">#</th>
              <th className="p-2 text-left text-sm font-semibold">
                {'Line Item'}
              </th>
              <th className="p-2 text-left text-sm font-semibold">
                {'Brand'}
              </th>
              <th className="p-2 text-left text-sm font-semibold">
                {'Specification'}
              </th>
              <th className="p-2 text-left text-sm font-semibold">
                {'Location'}
              </th>
              <th className="p-2 text-left text-sm font-semibold">
                {'Qty'}
              </th>
              <th className="p-2 text-left text-sm font-semibold">
                {'Unit Price'}
              </th>
              <th className="p-2 text-left text-sm font-semibold">
                {'Discount'}
              </th>
              <th className="p-2 text-left text-sm font-semibold">
                { 'Total'}
              </th>
             
            </tr>
          </thead>
          <tbody>
            
              <tr >
                <td className="p-2 text-sm min-w-[60px]">{1}</td>
                <td className="p-2 text-sm">
                  <div>
                    <p>{ '#00052 - Sample'}</p>
                    <p className="max-w-[350px] text-[#7D7C7C] text-[14px]">{'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc lectus sem, pharetra id dictum non, iaculis quis ex. Fusce hendrerit ligula sed sapien pharetra, in gravida metus rhoncus'}</p>
                  </div>
                </td>
                <td className="p-2 text-sm">{'brand'}</td>
                <td className="p-2 text-sm">{'each'}</td>
                <td className="p-2 text-sm">{'eac'}</td>
                
                <td className="p-2 text-sm">
                  { 200}
                </td>
                <td className="p-2 text-sm">
                              { 200}
                </td>
                <td className="p-2 text-sm">
                  {200}
                </td>
                <td className="p-2 text-sm">
                INR 1000.00
                </td>
              </tr>
          
                      
                        <tr className="border-t" >
                <td className="p-2 text-sm min-w-[60px]"></td>
                <td className="p-2 text-sm">
                   
                </td>
                
                <td className="p-2 text-sm"></td>
                <td className="p-2 text-sm"></td>
                <td className="p-2 text-sm"></td>
                <td className="p-2 text-sm">
                 
                </td>
                <td className="p-2 text-sm">
                               
                </td>
                          <td className="p-2 text-sm text-right">
                              Sub Total
                 </td>
                <td className="p-2 text-sm">
                INR 1000.00
                </td>
                      </tr>
                               <tr >
                <td className="p-2 text-sm min-w-[60px]"></td>
                <td className="p-2 text-sm">
                   
                </td>
                <td className="p-2 text-sm"></td>
              
                <td className="p-2 text-sm"></td>
                <td className="p-2 text-sm"></td>
                <td className="p-2 text-sm">
                 
                </td>
                <td className="p-2 text-sm">
                               
                </td>
                          <td className="p-2 text-sm text-right">
                            Discount
                 </td>
                <td className="p-2 text-sm">
                INR 200.00
                </td>
                      </tr>
                      
                       <tr >
                <td className="p-2 text-sm min-w-[60px]"></td>
                <td className="p-2 text-sm">
                   
                </td>
               
                <td className="p-2 text-sm"></td>
                <td className="p-2 text-sm"></td>
                <td className="p-2 text-sm"></td>
                <td className="p-2 text-sm">
                 
                </td>
                <td className="p-2 text-sm">
                               
                </td>
                          <td className="p-2 text-sm text-right">
                            GST (2.5%)
                 </td>
                <td className="p-2 text-sm font-[600]">
                INR 1000.00
                </td>
                      </tr>
                             
                       <tr >
                <td className="p-2 text-sm min-w-[60px]"></td>
                <td className="p-2 text-sm">
                   
                </td>
              
                <td className="p-2 text-sm"></td>
                <td className="p-2 text-sm"></td>
                <td className="p-2 text-sm"></td>
                <td className="p-2 text-sm">
                 
                </td>
                <td className="p-2 text-sm">
                               
                </td>
                          <td className="p-2 text-sm text-right">
                            State Tax (10%)
                 </td>
                <td className="p-2 text-sm font-[700]">
                INR 1000.00
                </td>
                      </tr>
                      
                                    
                       <tr >
                <td className="p-2 text-sm min-w-[60px]"></td>
                <td className="p-2 text-sm">
                   
                </td>
               
                <td className="p-2 text-sm"></td>
                <td className="p-2 text-sm"></td>
                <td className="p-2 text-sm"></td>
                <td className="p-2 text-sm">
                 
                </td>
                <td className="p-2 text-sm">
                               
                </td>
                          <td className="p-2 text-sm text-right">
                            Total
                 </td>
                <td className="p-2 text-sm font-[700]">
                INR 1000.00
                </td>
              </tr>
          </tbody>
        </table>
      </div>

     
          <div className="mt-5 flex justify-between border-b pb-7">
              <div >
                  <p className="font-[600] mb-1" >Payment Term</p>
                  <p>GPay</p>
              </div>
              <div>
                  <p className="font-[600] mb-1">Invoice Template</p>
                  <p>template</p>
              </div><div>
                  <p className="font-[600] mb-1">Remarks</p>
                  <p>thi is new </p>
              </div>

          </div>
          <div className="mt-5">
                  <p className="font-[600] mb-6">Other Details</p>
          <div className="mt-5 flex justify-between">
              
              <div>
                  <p className="font-[600]">Invoice Reference Number</p>
                  <p>dsds</p>
              </div>
            </div>

          </div>
    </div>
  );
};

export default ViewCustomerDetails;
