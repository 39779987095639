import SubHeader from "../../Components/Molecules/Subheader";
import Whitespace from "../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../Components/Atoms/ComponentTitle";
 import TodayIcon from '@mui/icons-material/Today';
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
const ReportsMainPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <SubHeader headings={[{ name: "Settings" }]}    actions={[
            {
              type: "button",
              name: "Scheduled Reports",

              onclick: () => {
                navigate("/report/scheduledreport");
              },
              icon: (
                <TodayIcon
                  sx={{
                    fontSize: "18px",
                    marginRight: "5px",
                    color: "#7F7F7F",
                  }}
                />
              ),
        },
        {
              type: "button",
              name: "Report History",              
              icon: (
                <QueryBuilderIcon
                  sx={{
                    fontSize: "18px",
                    marginRight: "5px",
                    color: "#7F7F7F",
                  }}
                />
              ),
            },
             
          ]}/>

      <div className="w-full">
      

        <Whitespace className={"mt-5 px-10"}>
          <ComponentTitle title={"CUSTOM REPORTS"} mb="7" />
          <div className="flex gap-x-40 gap-y-10 mb-8 flex-wrap">
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
               
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                    Custom Report One
                  </p>
                  
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/employee/customsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

              </div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                   Custom Report One
                  </p>
                  
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/timesheetsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
               <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                  <p className="text-sm mb-1 text-[#696D6E]">
                  Custom Report One
                  </p>
                 
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/quoteandinvoicesettings`)}
                className={`flex  gap-3 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
                  <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                   Custom Report One
                  </p>
                  
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                //   onClick={() => navigate(`${each1?.url}`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
                 <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                   Custom Report One
                  </p>
                 
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
               <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                  Custom Report One
                  </p>
                 
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
              
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                  Custom Report One
                  </p>
                 
                </div>
              </li>
            </ul>

            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                   Custom Report One
                  </p>
                  
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
  <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                   Custom Report One
                  </p>
                  
                </div>
              </li>
            </ul><ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                   Custom Report One
                  </p>
                  
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
                  <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                   Custom Report One
                  </p>
                  
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
               
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

                </div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                   Custom Report One
                  </p>
                  
                </div>
              </li>
            </ul>
          </div>
        </Whitespace>

        <Whitespace className={"mt-5 px-10"}>
          <ComponentTitle title={"JOB REPORTS"} mb="7" />
          <div className="flex gap-x-40 gap-y-10 mb-8 flex-wrap">
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
               
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                  Jobs Master Report
                  </p>
                  
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/employee/customsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

              </div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                   Jobs Status Report
                  </p>
                  
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/timesheetsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
               <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                  <p className="text-sm mb-1 text-[#696D6E]">
                   Status Summary Report
                  </p>
                 
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/quoteandinvoicesettings`)}
                className={`flex  gap-3 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
                  <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                  Status History Report
                  </p>
                  
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                //   onClick={() => navigate(`${each1?.url}`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
                 <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                Job Turnaround Time (TAT)
                  </p>
                 
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
               <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                  Job Notes
                  </p>
                 
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
              
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                   Avg Duration / Category
                  </p>
                 
                </div>
              </li>
            </ul>

            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                    Jobs by Team Report
                  </p>
                  
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
  <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                   Jobs Activity Report
                  </p>
                  
                </div>
              </li>
            </ul><ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                   Products Summary Report
                  </p>
                  
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
                  <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

</div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                    Products Details Report
                  </p>
                  
                </div>
              </li>
            </ul>
             
  
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
               
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

                </div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                    Customer Job Cohort
                  </p>
                  
                </div>
              </li>
            </ul>   <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
               
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

                </div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                   Customer Feedback
                  </p>
                  
                </div>
              </li>
            </ul>   <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
               
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

                </div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                    Jobs Checklist Report
                  </p>
                  
                </div>
              </li>
            </ul>   <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
               
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

                </div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                   Jobs by User Report
                  </p>
                  
                </div>
              </li>
            </ul>   <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
               
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

                </div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                   User Utilization
                  </p>
                  
                </div>
              </li>
            </ul>   <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
               
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

                </div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                    Product Consumption
                  </p>
                  
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
               
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

                </div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                   Recurring Jobs Report
                  </p>
                  
                </div>
              </li>
            </ul>

                <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
               
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

                </div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                 Job Timelog Report
                  </p>
                  
                </div>
              </li>
            </ul>    <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
               
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

                </div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                  Job Timelog Report
                  </p>
                  
                </div>
              </li>
            </ul>    <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
               
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

                </div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                   Route Details Report
                  </p>
                  
                </div>
              </li>
            </ul>    <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
               
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

                </div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                 Route Summary Report
                  </p>
                  
                </div>
              </li>
            </ul>    <ul className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col`}
              >
               
                <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10">

                </div>
                <div>
                <p className="text-sm mb-1 text-[#696D6E]">
                Job Service Task Report
                  </p>
                  
                </div>
              </li>
            </ul>
          </div>
        </Whitespace>
       
       <Whitespace className={"mt-5 px-10"}>
  <ComponentTitle title={"quotation & invoice reports"} mb="7" />

  {/* First row with 6 items */}
  <div className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-x-36 mb-8">
    <li className="flex gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col">
      <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10"></div>
      <p className="text-sm mb-1 text-[#696D6E]">Quote Master Report</p>
    </li>
    <li onClick={() => navigate(`/employee/customsettings`)} className="flex gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col">
      <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10"></div>
      <p className="text-sm mb-1 text-[#696D6E]">Quote by Status</p>
    </li>
    <li onClick={() => navigate(`/settings/timesheetsettings`)} className="flex gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col">
      <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10"></div>
      <p className="text-sm mb-1 text-[#696D6E]">Quote Status Summary</p>
    </li>
    <li onClick={() => navigate(`/settings/quoteandinvoicesettings`)} className="flex gap-3 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col">
      <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10"></div>
      <p className="text-sm mb-1 text-[#696D6E] whitespace-nowrap">Quote-Product Consumption</p>
    </li>
    <li className="flex gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col">
      <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10"></div>
      <p className="text-sm mb-1 text-[#696D6E]">Invoice Master Report</p>
    </li>
    <li className="flex gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col">
      <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10"></div>
      <p className="text-sm mb-1 text-[#696D6E]">Invoice Summary Report</p>
    </li>
  </div>

  {/* Second row with 5 items */}
  <div className="grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-x-36 mb-8">
    <li className="flex gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col">
      <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10"></div>
      <p className="text-sm mb-1 text-[#696D6E]">Invoice Status</p>
    </li>
    <li onClick={() => navigate(`/settings/leadsettings`)} className="flex gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col">
      <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10"></div>
      <p className="text-sm mb-1 text-[#696D6E]">Invoice - Product</p>
    </li>
    <li onClick={() => navigate(`/settings/leadsettings`)} className="flex gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col">
      <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10"></div>
      <p className="text-sm mb-1 text-[#696D6E]">Invoice - Payment</p>
    </li>
    <li onClick={() => navigate(`/settings/leadsettings`)} className="flex gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 flex-col">
      <div className="bg-[#c4c4c4] border-[7px] border-[#efefef] rounded-full h-10 w-10"></div>
      <p className="text-sm mb-1 text-[#696D6E]">Proposal Master</p>
    </li>
  </div>
</Whitespace>



      
      </div>
    </>
  );
};

export default ReportsMainPage;
