import React, { useRef, useState } from "react";
import Whitespace from "../../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";
import CustomInput from "../../../Components/Atoms/CustomInput";
import TextArea from "../../../Components/Atoms/TextArea";
import { TableIconConstant } from "../../../constants";
import CustomButton from "../../../Components/Atoms/CustomButton";
import FileInput from "../../../Components/Atoms/FileInput";
import CustomSelect from "../../../Components/Atoms/CustomSelect";
import InputLable from "../../../Components/Atoms/InputLable";
 

const SoDetails = (props) => {
  const handleFilesChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const obj = {
        file_name: e.target.files[i].name,
        url: "",
        created_by: props.userId,
        attachment: e.target.files[i],
      };
    }
  };
  const hiddenFileInput = useRef(null);
  const handleUploadBtn = () => {
    hiddenFileInput?.current?.click();
  };
  return (
    <>
      <Whitespace mt="23px" className={"px-7 py-7 w-full"}>
              <ComponentTitle title={"Sales Order SETTINGS"} />
              <div class="grid grid-cols-[1fr_3fr_2fr] gap-4">
                  <div>
                       <CustomInput
            margin="true"
            labelText="Prefix"
            name="username"
            placeholder="AB-001"
            type="text"
          />
  </div>
                  <div>
                       <CustomInput
            margin="true"
            labelText="Sales Order Title"
            name="username"
            placeholder="Enter Sales Order Title"
            type="text"
          />
                  </div>
                  <div>
                       <CustomSelect
            margin="true"
            labelText="Sales Order Category"
            name="username"
            placeholder="Choose a Sales Order category"
            type="text"
          />
                  </div>
</div>
        <div className="columnThree">
          <CustomInput
            margin="true"
            labelText="Sales Order Priority"
            name="username"
            placeholder="Choose Priority"
            type="text"
          />
          <CustomInput
            margin="true"
            labelText="Sales Order Type"
            name="username"
            placeholder="Choose Type"
            type="text"
          />
          <CustomInput
            margin="true"
            labelText="Parent Sales Order"
            name="username"
            placeholder="Choose parent Sales Order"
            type="text"
          />
        </div>
        <div className="columnThree">
          <CustomInput
            margin="true"
            labelText="Sales Order Recurrence"
            name="username"
            placeholder="Choose Recurrence"
            type="text"
                  />
                  <div>
                      <div className="mb-2">
                          <InputLable labelText="Sales Order Start Time" />
                          </div>
                      <div className="flex gap-2 w-full">
                          <div className="w-[50%]">
          <CustomInput       
    
            type="date"
                              />
                          </div>
                           <div className="w-[50%]">
          <CustomInput
             
             type="time"
                              />
                              </div>
                      </div>
                  </div>
                    <div>
                      <div className="mb-2">
                          <InputLable labelText="Sales Order End Time" />
                          </div>
                      <div className="flex gap-2 w-full">
                          <div className="w-[50%]">
          <CustomInput       
    
            type="date"
                              />
                          </div>
                           <div className="w-[50%]">
          <CustomInput
             
             type="time"
                              />
                              </div>
                      </div>
                      </div>
              </div>
               <div className="columnThree">
          <CustomInput
            margin="true"
            labelText="Sales Order Due Date"
            name="username"
           
            type="date"
          />
          <CustomSelect
            margin="true"
            labelText="Choose Contact"
            name="username"
            placeholder="Choose Contact"
            type="text"
          />
          <CustomInput
            margin="true"
            labelText=":"
            name="username"
            placeholder="Choose parent Sales Order"
            type="text"
          />
        </div>
        <TextArea labelText={"Description"} placeholder={"Enter Description"} />
          </Whitespace>
          
            <Whitespace mt="23px" className={"px-7 py-7 w-full"}>
        <ComponentTitle title={"OTHER DETAILS"} />
        <div className="columnThree">
          <CustomInput
            margin="true"
            labelText="Project Id"
            name="username"
            placeholder="Enter project id"
            type="text"
          />
          <CustomInput
            margin="true"
            labelText="Shipment Tracking Id"
            name="username"
            placeholder="Enter shipment tracking id"
            type="text"
          />
          <CustomInput
            margin="true"
            labelText="POC Phone Number"
            name="username"
            placeholder="Enter poc phone number"
            type="text"
          />
        </div>
        <div className="columnThree">
 <FileInput
            label={"File Input"}
            id="part-manual"
            text={"No Files Selected"}
          />          <CustomSelect
            margin="true"
            labelText="Sample Title"
            name="username"
            placeholder="Sample options"
            type="text"
          />
          <CustomSelect
            margin="true"
            labelText="Sample Title"
            name="username"
                       placeholder="Sample options"

            type="text"
          />
        </div>
      
      </Whitespace>
      <Whitespace className={"mt-5"}>
        <div className="flex items-center justify-between mb-10">
          <ComponentTitle title={"PARTS & SERVICES"}></ComponentTitle>
          <div className="flex">
            <CustomButton size="medium" buttonType={TableIconConstant.add}>
              {"Upload"}
            </CustomButton>
          </div>
        </div>
        <input
          onChange={handleFilesChange}
          ref={hiddenFileInput}
          style={{ display: "none" }}
          type="file"
          multiple
        />
        {/* {formik.values.attachments.length === 0 ? ( */}
        <div className="flex flex-col justify-center items-center py-9">
          <h1 className="text-[17px] font-semibold leading-[19px] text-black mb-[16px]">
            {"Add Parts & Services"}
          </h1>
          <p className="text-[14px] font-normal leading-[15px] text-[#9d9d9d] mb-[25px]">
            {
              "Add any parts or services of any custom line item to this Sales Order"
            }
          </p>

          <CustomButton
            size="medium"
            onClick={handleUploadBtn}
            buttonType={TableIconConstant.add}
          >
            {"Upload"}
          </CustomButton>
        </div>
          </Whitespace>
            <Whitespace className={"mt-5"}>
        <div className="flex items-center justify-between mb-10">
          <ComponentTitle title={"SERVICE TASKS"}></ComponentTitle>
          <div className="flex">
            <CustomButton size="medium" buttonType={TableIconConstant.add}>
              AddTasks
            </CustomButton>
          </div>
        </div>
        <input
          onChange={handleFilesChange}
          ref={hiddenFileInput}
          style={{ display: "none" }}
          type="file"
          multiple
        />
        {/* {formik.values.attachments.length === 0 ? ( */}
        <div className="flex flex-col justify-center items-center py-9">
          <h1 className="text-[17px] font-semibold leading-[19px] text-black mb-[16px]">
            {"Add Service Tasks"}
          </h1>
          <p className="text-[14px] font-normal leading-[15px] text-[#9d9d9d] mb-[25px]">
            
              Add any service tasks to this Sales Order
            
          </p>

 
        </div>
          </Whitespace>
          
            <Whitespace className={"mt-5"}>
        <div className="flex items-center justify-between mb-10">
          <ComponentTitle title={"Sales ORder attachments"}></ComponentTitle>
          <div className="flex">
            <CustomButton size="medium" buttonType={TableIconConstant.add}>
              UploadAttachments
            </CustomButton>
          </div>
        </div>
        <input
          onChange={handleFilesChange}
          ref={hiddenFileInput}
          style={{ display: "none" }}
          type="file"
          multiple
        />
        {/* {formik.values.attachments.length === 0 ? ( */}
        <div className="flex flex-col justify-center items-center py-9">
          <h1 className="text-[17px] font-semibold leading-[19px] text-black mb-[16px]">
            {"Upload Attachments"}
          </h1>
          <p className="text-[14px] font-normal leading-[15px] text-[#9d9d9d] mb-[25px]">
            {
              "Add any attachments to this Sales Order"
            }
                  </p>
                  <CustomButton size="medium" buttonType={TableIconConstant.add}>
              Upload
            </CustomButton>

 
        </div>
      </Whitespace>
    </>
  );
};

export default SoDetails;
