import AddIcon from "@mui/icons-material/Add";
import React, { useCallback, useState } from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SubHeader from "../../Components/Molecules/Subheader";
import FilterSortby from "../../Components/Molecules/FilterSortBy";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TableButton from "../../Components/Atoms/TableButton";
import { TableIconConstant } from "../../constants";
import CustomTable from "../../Components/Molecules/CustomTable";

const ProcessLossPage = () => {
    const navigate=useNavigate()
     const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const onClickFilter = () => {
    handleOpen();
  };
   
  const [page, setPage] = useState(1);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");

  const sortList = [
    { name: "Created Date", value: "createdAt-ASC" },
    { name: "Created Date", value: "createdAt-DSC" },
    { name: "Name-ASC", value: "name-ASC" },
    { name: "Name-DSC", value: "name-DSC" },
  ];
    
  

  return (
      <>
          
     
      <div className="">
     <SubHeader
          headings={[
            {
              name: "Production",
                      },
                {
              name: "Project no. 5901 ",
                      },
                  {
              name: " Process Loss",
                      },
                
          ]}
          actions={[
 
            {
              name: "New",
            onclick: () => {
                navigate("/processloss/form");
              },
              icon: (
                <AddIcon
                   
                  sx={{ fontSize: "15px" }}
                />
              ),
              type: "button",
              },
              {
                type: "menu_drop_down",
              
                children: [
                  {
                    label:  'Clone Process Loss',
                    
                     
                  },
                  {
                    label:   'Share Process Loss',
                    
                     
                    }, {
                      label:"Delete Process Loss",
                    }, 
                      
                ],
                name:  "More",
                icon: (
                  <KeyboardArrowDownIcon
                     
                    sx={{ fontSize: "18px", marginLeft: "8px" }}
                  />
                ),
             
              },
          ]}
              />
          

        <>
          <FilterSortby
            exportBtn={false}
            totalPage={"3"}
            getPageNumber={setPage}
            getQuickFilter={setQuickFilter}
            getSortByValue={setSortBy}
            sortList={sortList}
            roleList={[{ name: "All Roles", _id: "All Roles", disabled: true }]}
            showFilter={true}
            onClickFilter={onClickFilter}
                      
          />
           <div className="overflow-auto">
            <CustomTable
              tableTitle={[
                "Cost element",
                "Cost element",
                "Target cost",
                "actual cost",
                "t/i var.",
                "t/i var(%)",
                              "material loss",
                "time loss",
                              "quality loss",
                "energy loss",
                
                
                "ACTIONS",
              ]}
            //   navigateView={() => navigate("/employee/view")}
            //   tableData={data}
              viewButton={true}
              editButton={true}
              deleteButton={false}
            />
          </div>
        </>
      </div>{" "}
    </>
  );
};

export default ProcessLossPage;
